import Rails from "@rails/ujs"
import ApplicationController from "./application_controller"

// Implement temporary card controller before migrating original CardController
export default class extends ApplicationController {
  static values = {
    toggleUrl: String
  }
  // Call original CardController openCardClicked due to the original binds to $(document),
  // which prevents e.stopPropagation() from working
  toggle(event) {
    Rails.ajax({
      url: this.toggleUrlValue,
      type: "GET"
    })
  }
}
