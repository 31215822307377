import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "container",
    "contactToggler",
    "contactDropdownContainer",
    "taggedShipsToggler",
    "taggedShipDropdownContainer",
    "purchaseEnquiryLink",
    "taggedShipsLink"
  ]

  static values = { searchShipSuggestionUrl: String }

  connect(){
    this.textSelectionHandler = this.handleTextSelection.bind(this);
    this.containerTarget.addEventListener("mouseup", this.textSelectionHandler, false);
    this.containerTarget.addEventListener("touchend", this.textSelectionHandler, false);
    this.outsideClickHandler = this.handleOutsideClick.bind(this);
    window.addEventListener("click", this.outsideClickHandler, false);
    if (this.hasTaggedShipsTogglerTarget){
      $(document).on('big-dropdown:close', this.taggedShipsTogglerTarget, this.reset.bind(this));
    }
    if(this.hasContactTogglerTarget){
      $(document).on('big-dropdown:close', this.contactTogglerTarget, this.reset.bind(this));
    }
  }

  disconnect(){
    this.containerTarget.removeEventListener("mouseup", this.textSelectionHandler);
    window.removeEventListener("click", this.outsideClickHandler);
  }

  handleTextSelection(){
    if(this.getSelectionString().length){
      this.changeLinks();
      this.searchShipSuggestion();
    }
  }

  searchShipSuggestion(){
    if (this.hasTaggedShipsTogglerTarget) {
      var url = this.addParamToPath(this.searchShipSuggestionUrlValue);
      fetch(url)
        .then((response) => response.text())
        .then((data) => {
          this.taggedShipsTogglerTarget.innerHTML = data;
        })
    }
  }

  changeLinks(){
    if (this.hasTaggedShipDropdownContainerTarget && this.hasContactDropdownContainerTarget){
      [
        this.taggedShipDropdownContainerTarget,
        this.contactDropdownContainerTarget
      ].forEach(function(el){
        var path = el.dataset.contentUrl;
        el.dataset.contentUrl = this.addParamToPath(path);
      }.bind(this));
    }

    if (this.hasTaggedShipsLinkTarget){
      this.taggedShipsLinkTarget.href = this.addParamToPath(this.taggedShipsLinkTarget.getAttribute("href"));
    }

    this.purchaseEnquiryLinkTarget.href = this.addParamToPath(this.purchaseEnquiryLinkTarget.getAttribute("href"));
  }

  addParamToPath(path){
    var url = new URL("https://seefleet.com" + path);
    var search_params = url.searchParams;
    search_params.set("text", this.getSelectionString());
    var new_path = url.pathname + url.search;
    return new_path;
  }

  getSelectionString(){
    var selectedRange = "";
    var sel = window.getSelection && window.getSelection();
    if (sel && sel.rangeCount > 0) {
      selectedRange = window.getSelection().getRangeAt(0).toString();
    }
    return selectedRange;
  }

  reset(){
    this.resetDropdownContainers()
    this.resetUrls()
  }

  resetDropdownContainers(){
    if(this.hasTaggedShipDropdownContainerTarget) {
      this.taggedShipDropdownContainerTarget.innerHTML = "Loading";
      this.taggedShipDropdownContainerTarget.parentNode.style.height= "100px";
      this.taggedShipDropdownContainerTarget.parentNode.style.width= "400px";
    }

    if (this.hasContactDropdownContainerTarget){
      this.contactDropdownContainerTarget.innerHTML = "Loading";
      this.contactDropdownContainerTarget.parentNode.style.height= "100px";
      this.contactDropdownContainerTarget.parentNode.style.width= "300px";
    }

  }

  handleOutsideClick(e){
    // if text is deselected by clicked anywhere except the action button, reset the url
    if((this.hasTaggedShipsTogglerTarget && e.target != this.taggedShipsTogglerTarget) && !this.getSelectionString().length){
      this.resetUrls()
      this.resetTaggedShipsTogglerTarget()
    }
  }

  resetTaggedShipsTogglerTarget(){
    var svg = this.taggedShipsTogglerTarget.getElementsByTagName("svg")[0];
    this.taggedShipsTogglerTarget.innerHTML = svg.outerHTML + " Tag";
  }

  resetUrls(){
    [
      this.taggedShipDropdownContainerTarget,
      this.contactDropdownContainerTarget
    ].forEach(function(el){
      var path = el.dataset.contentUrl;
      var url = new URL("https://seefleet.com" + path);
      el.dataset.contentUrl = url.pathname;
    });
  }
}
