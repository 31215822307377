import ApplicationController from "./application_controller"
import consumer from "../channels/consumer"

export default class extends ApplicationController {
  static targets = ["stickyWall", "stickyWallOpen"]

  connect() {
    this.scrollToBottomInstantly()
  }

  scrollToBottomInstantly() {
    if (!this.hasStickyWallTarget || !this.stickyWallTarget.classList.contains("is-open")) {
      return
    }

    this.stickyWallTarget.scrollTop = this.stickyWallTarget.scrollHeight
  }

  stickyWallTargetConnected() {
    this.setupConsumer()
  }

  setupConsumer() {
    // Do not subscribe again if already subscribed
    for(const subscription in consumer.subscriptions.subscriptions) {
      if (subscription.identifier === '{"channel":"StickyWallChannel"}') {
        return
      }
    }

    consumer.subscriptions.create({channel: "StickyWallChannel"}, {
      received: this.dataReceived.bind(this)
    })
  }

  dataReceived(data) {
    const wall = this.stickyWallTarget

    // If this is segment specific, return fast if this is not relevant
    // (if it is not segment specific (segments.length === 0), we are good!)
    if (data.segments.length > 0) {
      if (!data.segments.includes(wall.dataset.segment)) {
        return
      }
    }

    if (wall.classList.contains("is-open")) {
      wall.insertAdjacentHTML("beforeend", data.html)
      this.scrollToBottom()
    } else {
      wall.innerHTML = data.html // when closed, just replace with last event
    }
  }

  scrollToBottom() {
    const stickyWall = this.stickyWallTarget

    if (!this.hasStickyWallTarget) {
      return
    }

    stickyWall.scroll({
      top: stickyWall.scrollHeight,
      behavior: "smooth"
    })
  }

  openStickyWall(e) {
    if (e.target.classList.contains("js-sticky-wall-override-open") || e.target.closest(".js-sticky-wall-override-open")) {
      return
    }

    this.stickyWallOpenTarget.click()
  }
}
