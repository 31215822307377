import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["dateElement",  "saveReminderEnabled", "saveReminderDisabled", "doneReminderEnabled", "doneReminderDisabled"]
  static values = {current: String, persisted: Boolean, status: String}

  connect() {
    this.reminderFormAdded()
  }

  disconnect() {
    this.reminderFormRemoved()
  }

  reminderFormAdded() {
    $(this.element).on("ajax:success", () => {
      if($(this.element).data("context") == "reminders_index") {
        $(".js-reminders-index-dropdown").trigger("reload")
      }
      window.cardController.closeCard()
      $.get($("[data-reminder-count]").data("countUrl"))
    })

    if(this.persistedValue && this.statusValue === "active") {
      $(this.element).on("change", this.dateElementTarget, (event) => {
        if($(event.currentTarget).val() != this.currentValue) {
          $(this.saveReminderEnabledTarget).removeClass("hidden")
          $(this.saveReminderDisabledTarget).addClass("hidden")

          $(this.doneReminderEnabledTarget).addClass("hidden")
          $(this.doneReminderDisabledTarget).removeClass("hidden")
        } else {
          $(this.saveReminderEnabledTarget).addClass("hidden")
          $(this.saveReminderDisabledTarget).removeClass("hidden")

          $(this.doneReminderEnabledTarget).removeClass("hidden")
          $(this.doneReminderDisabledTarget).addClass("hidden")
        }
      })
    }
  }

  reminderFormRemoved() {
    $(this.element).off("ajax:success")
    if(this.persistedValue && this.statusValue === "active") {
      $(this.element).off("change", '[data-controller="date-picker"]')
    }
  }
}
