import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    this.scrollTabsToActiveLink()
  }

  scrollTabsToActiveLink() {
    const tabs = this.element
    const activeLink = tabs.querySelector(".nav-link.active")
    if (!activeLink) {
      return
    }

    const smallScreenMaxWidth = parseInt(utils.getCSSVariable("--breakpoint-lg"), 10)
    // Using the lg breakpoint on card in general so using that here as well
    if (window.innerWidth > smallScreenMaxWidth) {
      return
    }

    const offset = activeLink.offsetLeft
    const midActiveLink = activeLink.offsetWidth / 2
    const midscreen = window.innerWidth / 2

    // Calculate so that the middle of the active link is positioned at the horizontal center of the viewport
    const scrollTo = offset - midscreen + midActiveLink

    // Scroll only for values that makes sense
    if (scrollTo > 0) {
      tabs.scrollLeft = scrollTo
    }
  }
}
