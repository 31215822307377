import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["map", "loadingText", "shipInGrid"]
  static values = {
    token: String,
    iconSvg: String,
    positionsUrl: String,
    shipInfoUrl: String,
    coordinates: String,
    iconPath: String
  }

  initialize() {
    if (this.hasMapTarget){
      this.drawMap()
      this.addShips()
      this.centerMap()
    }
  }

  drawMap(){
    mapboxgl.accessToken = this.tokenValue;
    this.map = new mapboxgl.Map({
      container: this.mapTarget, // container ID
      projection: "mercator",
      style: 'mapbox://styles/seefleet/cltr37ekd00zy01nrenfa98w5', // your style URL
      center: [0, 0], // starting position [lng, lat]
      zoom: 2 // starting zoom
    });
  }

  centerMap(){
    if(this.coordinatesValue){
      this.map.jumpTo({
        center: this.coordinatesValue.split(","),
        zoom: 2
      });
    }
  }

  addShips(){
    this.map.on('load', () => {
      this.map.loadImage(this.iconPathValue, (error, image) => {
        if (error) throw error;
        this.map.addImage('navigation-test', image, { 'sdf': true });

        this.map.addSource('ships', {
          type: 'geojson',
          data: this.positionsUrlValue,
          cluster: false
        });

        // Add a layer for the ships
        this.map.addLayer({
          id: 'ships',
          type: 'symbol',
          source: 'ships',
          layout: {
            'icon-image': "navigation-test",
            'icon-size': 0.3,
            'icon-rotate': ['get', 'rotation'],
            'icon-allow-overlap': true,
          },
          paint: {
            'icon-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#e83e8c',
              '#334b89'
            ]
          }
        });

        this.map.on('click', 'ships', (e) => {
          e.preventDefault();
          this.showShipText(e.features[0].geometry.coordinates, e.features[0].id );
        });

        // Change the cursor to a pointer when the mouse is over the ships layer.
        this.map.on('mouseenter', 'ships', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to the default when it leaves.
        this.map.on('mouseleave', 'ships', () => {
          this.map.getCanvas().style.cursor = '';
        });

        this.shipInGridTargets.forEach(ship => {
          ship.addEventListener('mouseenter', event => this.highlightShip(event));
        });

        this.shipInGridTargets.forEach(ship => {
          ship.addEventListener('mouseleave', event => this.resetShipHighlight(event));
        });
      });

    });
  }

  async showShipText(coordinates, imo_number) {
    const response = await fetch(`${this.shipInfoUrlValue}?imo_number=${imo_number}`);
    // const htmlContent = await response.text();

    const jsonResponse = await response.json();
    const htmlContent = jsonResponse.html;

    new mapboxgl.Popup({ maxWidth: "none"})
      .setLngLat(coordinates)
      .setHTML(htmlContent)
      .addTo(this.map);
  }

  highlightShip(event) {
    let imoNumber = event.target.dataset.imoNumber;

    this.map.setFeatureState(
      { source: 'ships', id: imoNumber },
      { hover: true }
    );
  }

  resetShipHighlight(event) {
    let imoNumber = event.target.dataset.imoNumber;
    this.map.setFeatureState(
      { source: 'ships', id: imoNumber },
      { hover: false }
    );
  }
}
