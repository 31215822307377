import ApplicationController from "./application_controller"
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = [
    "createNewProfileEl",
    "newProfileInput",
    "changeViewEl",
    "profileNameEl",
    "profileFormEl",
    "profileDefaultAttrEl"
  ]

  createNewGridFieldProfile() {
    if (this.newProfileInputTarget.value.length === 0) {
      return
    }

    this.profileNameElTarget.value = this.newProfileInputTarget.value
    this.profileDefaultAttrElTarget.value = false
    Rails.fire(this.profileFormElTarget, "submit")
    this.newProfileInputTarget.value = ""
  }

  handleNewProfileInput(e) {
    if (e.keyCode === 13) {
      this.createNewGridFieldProfile()
    }
  }

  changeView() {
    this.profileDefaultAttrElTarget.value = true
    this.profileNameElTarget.value = ""
    Rails.fire(this.profileFormElTarget, "submit")
  }
}
