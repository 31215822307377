# A place for utility functions we want to use throughout
window.utils =


  # Utility throttle function to ensure we do not call expensive functions
  # way too often. For instance on scroll events that fire rapidly, or when
  # triggering ajax requests based on form changes
  throttle: (callback, limit = 100) ->
    wait = false                  # Initially, we're not waiting
    return (args...) ->           # We return a throttled function
      if !wait                    # If we're not waiting
        callback(args...)         # Execute users function
        wait = true               # Prevent future invocations
        setTimeout( ->            # After a period of time
          wait = false;           # And allow future invocations
        , limit)


  # Utility debounce function to ensure we do not call expensive functions
  # Different from throttle, the timout will restart every time the function is called again
  debounce: (callback, limit = 100) ->
    timeout = null
    return (args...) ->
      clearTimeout(timeout)
      timeout = setTimeout( ->
        callback(args...)
      , limit)

  # Get a CSS variable by the variable name
  # for instance:
  #
  #   utils.getCSSVariable("--warning");
  #
  getCSSVariable: (variableName) ->
    getComputedStyle(document.documentElement).getPropertyValue(variableName).trim()

  # Format a number and return a string on the form "23,234,543"
  #
  # For instance:
  #
  #   utils.numberWithCommas(12345.67) => "12,345.67"
  numberWithCommas: (number) ->
    parts = number.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".");


  truncate: (text, n = 30) ->
    if text.length > n
      text.substr(0, n-1) + "…"
    else
      text
