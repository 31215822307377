import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  // reordering code is from https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript
  reorder() {
    var table = this.element;
    var th = event.currentTarget;

    var getCellValue = function(tr, idx){
      return tr.children[idx].querySelector("input:not([type=hidden]")?.value || tr.children[idx].innerText || tr.children[idx].textContent;
    }

    var comparer = function(idx, asc) { return function(a, b) { return function(v1, v2) {
            return v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2);
        }(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));
    }};

    Array.prototype.slice.call(table.querySelectorAll("tbody tr:not(.hidden)"))
      .sort(comparer(Array.prototype.slice.call(th.parentNode.children).indexOf(th), this.asc = !this.asc))
      .forEach(function(tr) { table.appendChild(tr.parentNode) });
  }
}
