import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  ensureMutuallyExclusive(e) {
    // If changed checkbox was just checked, ensure all other checkboxes in this
    // mutually exclusive group is not checked
    const $changedCheckbox = $(e.currentTarget)
    if ($changedCheckbox.is(":checked")) {
      return $(".js-mutually-exclusive-checkboxes :checkbox").each(function(i, checkbox) {
        // Compare raw DOM elements and stop is the iterated element is the same
        // as the one just changed
        if (checkbox === $changedCheckbox[0]) {
          return
        }
        // Finally, make sure checkbox is not checked
        return $(checkbox).prop("checked", false)
      })
    }
  }
}
