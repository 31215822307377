import SquireEditor from "./squire/editor"
import { controllerExpose } from "../utils/stimulus_utils"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    disableHandleDrop: Boolean,
  }

  static targets = [
    "toolbar",
    "content",
    "button",
    "contentHiddenField",
    "urlDialog",
    "urlInput",
  ]

  connect() {
    controllerExpose(this)

    const squireEditor = new SquireEditor(
      this.toolbarTarget,
      this.buttonTargets,
      this.contentTarget,
      this.contentHiddenFieldTarget,
      this.urlDialogTarget,
      this.urlInputTarget,
      this.disableHandleDropValue
    )

    this.editor = squireEditor.editor
  }

  disconnect() {
    this.editor.destroy()
  }
}
