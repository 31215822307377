import ApplicationController from "./application_controller"
import consumer from "../channels/consumer"

export default class extends ApplicationController {
  static targets = ["listContainer"]

  initialize() {
    this.subscription = consumer.subscriptions.create(
      { channel: "EmailCircularChannel" },
      {
        received: this.insertNewCircular.bind(this)
      }
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  insertNewCircular(data) {
    this.listContainerTarget.insertAdjacentHTML("afterbegin", data.html)
  }
}
