class PitchController

  constructor: ->

    # Hooking into the when the bootstrap dropdown is opened
    $(document).on("show.bs.dropdown", ".js-pitch-form-dropdown", this.pitchDropdownOpened)

  pitchDropdownOpened: (e) =>
    # Whenever the pitch dropdown is opened, we want to make sure we have the
    # latest version of the pitch form on the server side so we can respond
    # with a completely updated mailto link in the dropdown menu
    url = $(".js-pitch-form").attr("action")

    # if the pitch view is HTML table, make sure to copy over any changes from
    # the contenteditable table to the hidden textarea to updated mailto body
    if $(".js-pitch-form").data().tableView
      $(".js-pitch-form textarea#pitch_text").text($(".js-pitch-form .js-pitch-body").html())

    $.post url, $(".js-pitch-form").serialize(), (response) =>
      $(".js-dropdown-contents").html(response)


# Register on window scope
window.pitchController = new PitchController()
