import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["cc", "bcc"]

  showCc() {
    this.toggleClass(this.ccTarget);
  }

  showBcc() {
    this.toggleClass(this.bccTarget);
  }

  toggleClass(element){
    if(element.classList.contains('d-none')) {
      element.classList.remove("d-none");
    } else{
      element.classList.add("d-none");
    }
  }
}
