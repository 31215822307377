hideDropdownLists = () ->
  $(".js-dropdown-list.js-auto-hide").hide()
  $(".dropdown-button").removeClass("focus")

showDropdownLists = () ->
  hideDropdownLists()

  list = $(this).siblings(".js-dropdown-list")
  list.toggle()

  if list.is(":visible")
    $(this).addClass('focus')

    setTimeout ->
      $(".js-focus-area:visible").focus()
  return

selectDefault = () ->
  container = $(this).closest(".js-multi-select")
  container.find("input:checkbox:checked").click()
  container.find("input.default:checkbox:not(:checked)").click()

# Events
$(document).on("click", ".js-multi-select .dropdown-button", ->
  showDropdownLists.call(this)
).on("input", ".js-multi-select .js-focus-area", ->
  target = $(this)
  dropdownList = target.closest(".js-dropdown-list")
  search = target.val().toLowerCase()

  if !search
    dropdownList.find("li").show()
    return false

  dropdownList.find("li").each ->
    text = $(this).text().toLowerCase()
    match = text.indexOf(search) > -1
    $(this).toggle match
    return
  return

).on("change", ".js-multi-select input:checkbox", ->
  container = $(this).closest(".js-multi-select")
  numChecked = container.find("[type='checkbox']:checked").length
  container.find(".js-quantity").text numChecked or "0"
  return

).on("click", ".js-multi-select .js-clear-all", ->
  container = $(this).closest(".js-multi-select")
  container.find("input:checkbox:checked").click()
  return

).on("click", ".js-multi-select .js-select-all", ->
  container = $(this).closest(".js-multi-select")
  container.find("input:checkbox:not(:checked)").click()
  return

).on("click", ".js-multi-select .js-select-default", ->
  selectDefault.call(this)
  return

).on("click", ".js-advanced-search-form .js-clear", (e) ->
  e.preventDefault()
  $(".js-multi-select .js-quantity").text "0"
  $(".js-multi-select .js-select-default").click()
  return

).on("click", ".js-select-cancel", (e) ->
  e.preventDefault()
  hideDropdownLists()
  return
).on("click", (e) ->
  if $(e.target).closest(".js-multi-select").length == 0
    hideDropdownLists()
  return
)
