import { Controller } from "@hotwired/stimulus"

const enterKey = 13
const tabKey = 9

export default class extends Controller {
  static targets = [ "query" ]
  static values = { url: String }
  static outlets = [ "autocomplete-results" ]

  disconnect() {
    this.reset()
  }

  fetchResults() {
    if(this.query == "") {
      this.reset()
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.urlValue)
    url.searchParams.append("q", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.autocompleteResultsOutlet.setPosition(this.element)
        this.autocompleteResultsOutlet.setHtml(html)
        this.autocompleteResultsOutlet.selectFirstResult()
      })
      .catch((e) => {
        // Try not to this error to avoid noise in Appsignal
        // console.log("catch", e)
      })
  }

  // Triggered on keyDown
  navigateResults(event) {
    if(!this.hasAutocompleteResultsOutlet) {
      return
    }

    this.autocompleteResultsOutlet.navigateResults(event)

    // Tab or Enter was used. Store currently selected value from
    // result to text field
    if([tabKey, enterKey].includes(event.keyCode)) {
      this.autocompleted()
    }

    // We do not want to submit form if Enter was used
    if(event.keyCode == enterKey) {
      event.preventDefault()
    }
  }

  autocompleted() {
    var newValue = this.autocompleteResultsOutlet.selectedResultValue();
    if(newValue != "") {
      this.queryTarget.value = newValue
      // Let's broadcast this as an event so we can hook things up from other controllers if we want
      const event = this.dispatch("autocompleted", { detail: { input: this.queryTarget, selectedItem: this.autocompleteResultsOutlet.selectedResultElement() } })
      // Make sure the text in the input field is selected. Makes sense when
      // resultOutlet has been clicked using mouse
      this.queryTarget.select()
    }
  }

  reset() {
    // Abort any ongoing ajax requests so we don't get results from them
    this.abortPreviousFetchRequest()
    if(this.hasAutocompleteResultsOutlet) {
      this.autocompleteResultsOutlet.reset(this)
    }

    this.previousQuery = null
  }

  // private

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }

  closeResults() {
    // This is called on the blur event, to make sure we don't end up having an
    // opened autocomplete dropdown that stays open. The problem is that we don't
    // want to clear everything right away, as we might need to read the selected
    // result after a click event in the automplete results controller, but that
    // is triggered after the blur event that triggers this function.

    // Therefore we need to do this with a delay, and check if the element of the
    // target input for the current active controller is in focus. If it's not, we
    // can close down the results dropdown
    setTimeout(() => {
      this.autocompleteResultsOutlet.closeResultsIfInputNoLongerInFocus()
    }, 100)
  }
}
