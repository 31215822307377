import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  static targets = [ "form", "port", "country", "zone", "submitButtonSave", "submitButtonSaved" ]

  // Activated when port is 'autocompleted', with the 'autocomplete:autocompleted' event
  setCountryAndZone(event) {
    this.setCountry(event)
    this.setZone(event)
  }

  setCountry(event) {
    // The column may have been removed from the ports view
    if(!this.hasCountryTarget) {
      return
    }

    var countryName = event.detail.selectedItem.dataset.portCountryName
    if (countryName){
      this.countryTarget.value = countryName
    }
  }

  // Activated when country is 'autocompleted', that is the 'autocomplete:autocompleted' 
  // event or programmatically from setCountryAndZone
  setZone(event) {
    // The column may have been removed from the ports view
    if(!this.hasZoneTarget) {
      return
    }
    var zoneName = event.detail.selectedItem.dataset.portZoneName
    if (zoneName){
      this.zoneTarget.value = zoneName
    }
  }

  // Triggered on focus on form elements
  updateSubmitButton() {
    // Depending on context we might not have these submit
    // buttons (on the ship card)
    if(this.hasSubmitButtonSaveTarget) {
      this.submitButtonSaveTarget.classList.remove("hidden")
    }
    if(this.hasSubmitButtonSavedTarget) {
      this.submitButtonSavedTarget.classList.add("hidden")
    }
  }

  blurSubmitButton() {
    // Do not blur the submit button if form has actually changed
    if(this.hasFormChanged) {
      return
    }
    this.submitButtonSaveTarget.classList.add("hidden")
    this.submitButtonSavedTarget.classList.remove("hidden")
  }

  formChanged(event) {
    // Keep track of wether the form has actually changed.
    // If so we want to keep an active submit button even though
    // moving focus out of the form
    this.hasFormChanged = true
  }

  // Triggered by keyboard shortcuts
  submitForm() {
    // Using form.submit() is unreliable, so using the Rails.fire wrapper instead:
    Rails.fire(this.formTarget, "submit")
  }
}
