# A controller for working on selected items (using a custom svg-checkbox)
#
# There are several groups of selected items (currently 'contact' and 'ship')
# so this controller separates them by expecting a data-item-type=ship|contact
# on every clicked element. Any update performed will only be reflected on
# .js-* elements annotated with a corresponding data-item-type matching the content
# type of the clicked element
class SelectedItemsController

  constructor: ->

    # Toggle a single item
    $(document).on("click", ".js-toggle-selected-item", this.toggleSelectedItem)
    # Toggle an entire list
    $(document).on("click", ".js-toggle-all-selected-items", this.toggleAllSelectedItems)
    # Clear all selected
    $(document).on("click", ".js-clear-all-selected-items", this.clearAllSelectedItems)
    # Toggle the selection of a collection of items
    $(document).on("click", ".js-toggle-collection-selected-items", this.toggleCollectionOfSelectedItems)


  # Toggle one selected item using the item's data-toggle-url
  toggleSelectedItem: (e) =>
    # Stop click event from bubbling to a surrounding element
    e.stopPropagation()

    $clickContainer = $(e.currentTarget)
    $element = $clickContainer.find(".js-checkmark-container")
    itemType = this.getItemType($element)
    # Indicate that we are loading
    $element.addClass("loading")

    $.post($element.data("toggleUrl"), (response) =>

      $element
        # Need to be excplicit about updating both in the
        # DOM (attr()) and in memory (data())
        .attr("data-checked", response.checked)
        .data("checked", response.checked)
        .removeClass("loading")

      $element.removeClass("loading")

      # Finally update any counter
      this.updateSelectedItemsCount(itemType, response.count)

      if response.collections
        # Update collection states (checkboxes)
        this.updateCollections(itemType, response.collections)
        # Update collection counts
        this.updateCollectionCounts(itemType, response.collections)

      if response.items
        # Update any selected items in page (checkboxes)
        this.updateSelectedItems(itemType, response.items)

      if response.invalid_count?
        this.updateInvalidCount(response.invalid_count)
    )

  # Toggle all items in a list of selected items
  # Using a toggle-url, there might be parameters
  # filtering what items should be selected.
  # Expects JSON back with all selected item ids
  toggleAllSelectedItems: (e) =>
    e.preventDefault() # In case clicked element was a link

    $clickContainer = $(e.currentTarget)
    $element = $clickContainer.find(".js-checkmark-container")

    itemType = this.getItemType($element)

    $element.addClass("loading")

    # If the checkbox does not have checked state, we should
    # check all otherwise, we should deselect all
    shouldCheck = !$element.data("checked")

    $.post($element.data("toggleUrl"), {check_all: shouldCheck}, (response) =>
      this.updateSelectedItems(itemType, response.items)
      this.updateAllCheckbox(itemType, response.checked)
      this.updateSelectedItemsCount(itemType, response.count)
      if response.invalid_count?
        this.updateInvalidCount(response.invalid_count)
    )

  # Toggle selection/deselection of a collection of items
  # That is, for an entire list of items, select or deselect
  # all of them
  toggleCollectionOfSelectedItems: (e) =>
    e.preventDefault() # In case clicked element was a link

    $clickContainer = $(e.currentTarget)
    $element = $clickContainer.find(".js-checkmark-container")

    itemType = this.getItemType($element)

    $element.addClass("loading")

    # If the checkbox does not have checked state, we should
    # check all otherwise, we should deselect all
    # The checked state can be data-checked = "true"|"false"|"disabled"
    # Anything else than true means we should check.
    shouldCheck = $element.data("checked").toString() != "true"

    $.post($element.data("toggleUrl"), {check_all: shouldCheck}, (response) =>
      # Update any selected items in page (checkboxes)
      this.updateSelectedItems(itemType, response.items)
      # Update selected items count (typically in dropdown)
      this.updateSelectedItemsCount(itemType, response.count)
      # Update collection states (checkboxes)
      this.updateCollections(itemType, response.collections)
      # Update collection counts
      this.updateCollectionCounts(itemType, response.collections)
      if response.invalid_count?
        this.updateInvalidCount(response.invalid_count)
    )

  # Update collection check boxes with state
  # Expects a list of dicts with id and checked_state for each collection
  # to update. Valid checked_state values are
  #    false true or disabled
  #
  # [
  #   "23": {checked_state: "false"},
  #   "45": {checked_state: "true"},
  #   "49": {checked_state: "disabled"}
  # ]
  updateCollections: (itemType, collections) ->

    $(".js-toggle-collection-selected-items").find(".js-checkmark-container[data-item-type=#{itemType}]").each (i, element) =>
      $element = $(element)
      collectionId = $element.data("collection-id")

      checkedState = collections[collectionId]["checked_state"]

      $element
        # Need to be excplicit about updating both in the
        # DOM (attr()) and in memory (data())
        .attr("data-checked", checkedState)
        .data("checked", checkedState)
        .removeClass("loading")

  #
  # Expects a list on the form
  #
  # [
  #   "23": {selected_count: 5},
  #   "45": {selected_count: 0},
  #   "49": {selected_count: 24}
  # ]
  #
  # where keys are collection ids and values are counts.
  # Will loop through all .js-collection-selected-count
  # and update counts according to the data-collection-id
  # attribute expected on the same element as the .js-collection-selected-count
  updateCollectionCounts: (itemType, collectionSelectedCounts) =>

    $(".js-collection-selected-count[data-item-type=#{itemType}]").each (i, element) =>
      $element = $(element)
      collectionId = $element.data("collection-id")
      selectedCount = collectionSelectedCounts[collectionId]["selected_count"] || 0

      $element.text(selectedCount)

  updateInvalidCount: (count) =>
    $(".js-invalid-count").text(count)


  # Post to the triggering elements data-clear-url
  # and updates the DOM on successful response
  clearAllSelectedItems: (e) =>
    e.preventDefault() # In case clicked element was a link

    $element = $(e.currentTarget)
    itemType = this.getItemType($element)

    $.post($element.data("clearUrl"), (response) =>
      this.updateSelectedItems(itemType, response.items)
      this.updateAllCheckbox(itemType, false)
      this.updateSelectedItemsCount(itemType, response.count)
      this.updateCollections(itemType, response.collections)
      this.updateCollectionCounts(itemType, response.collections)
      if response.invalid_count?
        this.updateInvalidCount(response.invalid_count)
    )

  # Update any "select all" checkboxes for the given
  # itemType
  updateAllCheckbox: (itemType, checked) =>

    $(".js-toggle-all-selected-items").find(".js-checkmark-container[data-item-type=#{itemType}]").each (i, element) =>
      $element = $(element)
      # Set the state on the "all" checkbox
      $element.attr("data-checked", checked)
      $element.data("checked", checked)
      $element.removeClass("loading")


  updateSelectedItems: (itemType, selectedItems) =>

    # Run through all single items to select and select/deselect them
    $(".js-toggle-selected-item").find(".js-checkmark-container[data-item-type=#{itemType}]").each (i, element) =>
      $element = $(element)
      itemId = $element.data("itemId")

      checked = itemId in selectedItems

      $element
        # Need to be excplicit about updating both in the
        # DOM (attr()) and in memory (data())
        .attr("data-checked", checked)
        .data("checked", checked)
        .removeClass("loading")

  updateSelectedItemsCount: (itemType, count) =>
    $(".js-selected-items-count[data-item-type=#{itemType}]").text(count)


  # Utility function to extract item type but blow up
  # if no data-item-type was found on the element
  getItemType: ($element) =>

    $element.data("itemType") || throw("Could not find a data-item-type on element")


# Instantiate the controller as a singleton in the DOM
# Note that the singleton pattern is important here, so
# we cannot store state on the instance of the controller,
# but rather pass data attributes/information around on the
# instance functions
window.selectedItemsController = new SelectedItemsController()
