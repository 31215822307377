import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["submitDiv"]

  textAreaFocused(e) {
    this.submitDivTarget.classList.remove("hidden")
    e.currentTarget.classList.add("focused")
  }

  textAreaBlurred(e) {
    const textArea = e.currentTarget
    // Wait a little bit so the button will not be hidden before the form is submitted
    setTimeout(() => {
      textArea.classList.remove("focused")
    }, 4000)
  }
}
