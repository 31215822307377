# JS utility to help set up form on each table row of our Ship grid view
class TrFormController

  constructor: ->
    this.submitSelector = ".js-tr-form-submit"
    this.submitAllSelector = ".js-tr-form-submit-all"

    $(document).on "click", this.submitSelector, this.submitOne
    $(document).on "click", this.submitAllSelector, this.submitAll

  submitOne: (event) =>
    event.preventDefault()
    event.stopPropagation()

    $element = $(event.currentTarget)

    paramsKey = $element.data("params-key")
    $tr = $element.closest(".js-tr-form-row")
    inputs = $.merge($tr.find("input"), $tr.find("select"))

    params = {}
    params[paramsKey] = {}

    for input in inputs
      params[paramsKey][$(input).attr("name")] = $(input).val()

    this._ajax($element, params)
    return

  submitAll: (event) =>
    event.preventDefault()
    event.stopPropagation()

    $element = $(event.currentTarget)

    paramsKey = $element.data("params-key")
    params = {}
    params[paramsKey] = []

    for tr in $(".js-tr-form-row")
      inputs = $.merge($(tr).find("input"), $(tr).find("select"))
      rowParams = {}

      for input in inputs
        rowParams[$(input).attr("name")] = $(input).val()

      if Object.keys(rowParams).length > 0
        params[paramsKey].push rowParams

    this._ajax($element, params)
    return

  _ajax: ($element, params) =>
    $.ajax
      contentType: "application/json"
      data: JSON.stringify params
      dataType: "script"
      method: $element.data("method")
      url: $element.data("url")

window.trFormController = new TrFormController()
