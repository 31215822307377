import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import Appsignal from "@appsignal/javascript";
const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_JS_TOKEN
});
import { installErrorHandler } from "@appsignal/stimulus";

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// catch errors and report to appsignal
installErrorHandler(appsignal, application);

// Controllers autoloading
const context = require.context("../stimulus_controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

export { application }