import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "badge" ]

  initialize() {
    this.subscription = consumer.subscriptions.create(
      { channel: "MentionsChannel" },
      {
        received: this.insertMentionsCount.bind(this)
      }
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  insertMentionsCount(data) {
    this.badgeTarget.innerText = data.count
  }
}
