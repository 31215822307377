import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  initialize() {
    this.alertDisplayed = false

    this.maxFileSize = 4 * 1024 * 1024 // 4 Megabytes
    this.fileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"]
    this.attachFilesButton = this.element.closest("div")
      .querySelector('[data-trix-action="attachFiles"]')

    // When click on attachFiles button, reset state of this.alertDisplayed
    this.attachFilesButton.addEventListener("click", () => {
      this.alertDisplayed = false
    })
  }

  fileAcceptHandler(event) {
    const fileTypesAllowed = this.fileTypesAllowed(event.file.type)
    const fileSizeAllowed = this.fileSizeAllowed(event.file.size)

    if (fileTypesAllowed && fileSizeAllowed) {
      return
    }

    event.preventDefault()

    // Stop displaying alert if there was an alert displayed before, in case there are multiple invalid files selected
    if (this.alertDisplayed === false) {
      window.alert("Please only attach png, jpeg, jpg or gif files <= 4 Megabytes!")
    }

    this.alertDisplayed = true
  }

  fileTypesAllowed(fileType) {
    return this.fileTypes.includes(fileType)
  }

  fileSizeAllowed(fileSize) {
    return fileSize <= this.maxFileSize
  }
}
