// This is a complex stimulus controller due to the complex nature of select2 and our requirements for the recipient input field.
// Complete recipient input field's behaviors description can be found at: https://github.com/seefleet/ships/pull/704#issue-1110097748
// Take your time to read the description above and comments below before trying to change something.
// Please update the description (MUST) and comments (SHOULD) after making any change.

import ApplicationController from "./application_controller"
import Rails from "@rails/ujs"
import { escape } from "lodash-es"
import { isMatchEmailPatternRegex, getEmailFromSelect2SelectedOption, isSelect2SelectedOption } from "../utils/email_utils"

// Naming explanation (based on select2 doc)
//   search field = search box = the input field where user can enter the search term
//   term = search term = inputted value = pasted value = the value which user inputted or pasted to the search box for ajax searching
//   selected option = selected item = the selected option which is shown in the search box
//   highlighted result = highlighted option = the highlighted (first by default) ajax search result in the dropdown

export default class extends ApplicationController {
  static targets = ["selectField"]

  static values = {
    addressType: String,
    addAddressUrl: String,
    removeAddressUrl: String,
    selectedRecipients: Array,
    searchAllContactsUrl: String,
    validEmailPatternObject: Object
  }

  connect() {
    this.selectField = $(this.selectFieldTarget)
    this.addCustomEventHandlers()
    this.initAddressSelect()

    // Store select2's remote data results to later check against our custom tags
    this.remoteDataResults = []
  }

  disconnect() {
    this.removeCustomEventHandlers()
  }

  // Programmatically retrieve select2's selected options: https://select2.org/programmatic-control/retrieving-selections#using-the-data-method
  get selectedOptions() {
    return this.selectField.select2("data")
  }

  // Original naming from https://github.com/select2/select2/blob/4.0.13/src/js/jquery.select2.js#L29
  get select2Instance() {
    return this.selectField.data("select2")
  }

  isTargetMatchesSearchField(event) {
    // Find the search field to use ajax to search for email in Contact and Email::UsedEmailAddress
    const searchField = this.select2Instance.$container.find("input.select2-search__field")[0]
    // We may encounter error with select2 (in system test for example)
    if (!searchField) {
      return false
    }

    // Ensure the target of the event is the search field because we are listening for the event on the whole document.
    if (event.target !== searchField) {
      return false
    }

    return true
  }

  newSelectedOptionData(email) {
    return {
      id: email, // an option without id can't be selected by select2
      text: email,
      display_name: email
    }
  }

  addSelectedOption(email) {
    // Create a new selected HTMLOptionElement for the email
    const option = new Option(email, email, false, true)
    // Append that option to the recipient input field and trigger the change event to update the UI
    this.selectField.append(option).trigger("change")
    // Trigger `select2:select` event to create a new Email::MessageEmailAddress record
    const data = this.newSelectedOptionData(email)
    this.selectField.trigger({
      type: "select2:select",
      params: {
        data
      }
    })
  }

  closeSelect2Dropdown() {
    this.selectField.select2("close")
  }

  // There are 3 "events" in an ajax search lifecycle:
  // - isWaitingForAjaxResults: User inputted the search term, and select2 fired an ajax request to search for
  //   a match email in Contact and Email::UsedEmailAddress. The ajax response isn't present at this point
  // - hasResult: The ajax response is present and we have at least a match (fully or partially)
  // - hasNoResult: The ajax response is present and we don't have any match
  //
  // If we are waiting for ajax results or we get no result
  //   Turn the search term into a selected option if it is a valid email and not previously selected.
  //   Otherwise do nothing
  // Else (if we get at least one result)
  //   Select the email in the first highlighted option in the dropdown if it is not previously selected.
  //   Otherwise do nothing
  /**
   * @param {object} e "keydown" event
   */
  handleTabKeyDown(e) {
    const isDropdownOpening = this.select2Instance.isOpen()
    // For example when we press Tab after selecting an option.
    if (!isDropdownOpening) {
      return
    }

    const isWaitingForAjaxResults = this.select2Instance.$dropdown.has(".loading-results").length > 0
    const hasNoResult = this.select2Instance.$dropdown.has(":contains('No results found')").length > 0
    const trimmedSearchTerm = e.target.value.trim()

    if (isWaitingForAjaxResults || hasNoResult) {
      if (isMatchEmailPatternRegex(trimmedSearchTerm, this.validEmailPatternObjectValue)) {
        if (isSelect2SelectedOption(this.selectedOptions, trimmedSearchTerm)) {
          return
        }

        this.addSelectedOption(trimmedSearchTerm)
      } else {
        // Prevent the dropdown from being closed if the trimmed search term is an invalid email.
        e.preventDefault()

        // The "keydown" event may propagate to other event listeners
        // (for example, select2's "keydown" event listener).
        e.stopImmediatePropagation()
      }
    } else {
      const highlightedAjaxResult = this.select2Instance.$results.find("li.select2-results__option--highlighted")
      if (highlightedAjaxResult.length > 0) {
        // We wrap the email in a <small></small> tag in the templateResult option.
        const email = highlightedAjaxResult.find("small").text()

        if (isSelect2SelectedOption(this.selectedOptions, email)) {
          this.closeSelect2Dropdown()
          return
        }

        this.addSelectedOption(email)
      }
    }
  }

  // This is the container for keydown handlers of all keys that we want to custom.
  // It is initialized from this.addCustomKeyDownHandler().
  // We can't use select2's default selecting features in our email selecting use cases on the recipient input fields.
  // Usage of each keydown handler for each key will be documented in their corresponding keydown handler.
  /**
   * @param {object} e "keydown" event
   */
  keyDownHandler(e) {
    if (!this.isTargetMatchesSearchField(e)) {
      return
    }

    const TAB_KEY = 9
    // Use switch case for easy logic extending later
    switch (e.which) {
      case TAB_KEY:
        this.handleTabKeyDown(e)
        break
    }
  }

  /**
   * @param {object} e "input" event
   */
  handleTokenSeparators(e) {
    // Container for all separators, only modify this container to get the desired regexp pattern
    const tokenSeparators = [",", " ", ";"]
    // Use RegExp `?:` non-capturing group to make all separators act as one when they stand next to each other
    const tokenSeparatorsPattern = new RegExp("(?:" + tokenSeparators.join("|") + ")+")

    // Do nothing if the search term doesn't include any token separator
    if (!tokenSeparatorsPattern.test(e.target.value)) {
      return
    }

    const separatedSearchTerms = e.target.value.split(tokenSeparatorsPattern)
    let invalidSearchTerms = []

    for (const separatedSearchTerm of separatedSearchTerms) {
      if (!isMatchEmailPatternRegex(separatedSearchTerm, this.validEmailPatternObjectValue)) {
        invalidSearchTerms.push(separatedSearchTerm)
      } else {
        const trimmedSearchTerm = separatedSearchTerm.trim()

        if (isSelect2SelectedOption(this.selectedOptions, trimmedSearchTerm)) {
          continue
        }

        this.addSelectedOption(trimmedSearchTerm)
      }
    }

    const hasValidSearchTerms = separatedSearchTerms.length > invalidSearchTerms.length
    if (hasValidSearchTerms) {
      // Prevent select2 from handling the "input" event.
      e.preventDefault()
      e.stopImmediatePropagation()

      this.closeSelect2Dropdown()

      if (invalidSearchTerms.length === 0) {
        // Leave only the selected options
        e.target.value = ""
      } else {
        // Add the invalid search terms behind the selected options
        e.target.value = invalidSearchTerms.join(`${tokenSeparators[0]} `)
      }
    }
  }

  /**
   * @param {object} e "input" event
   */
  inputHandler(e) {
    if (!this.isTargetMatchesSearchField(e)) {
      return
    }

    this.handleTokenSeparators(e)
  }

  addCustomEventHandlers() {
    this.customKeyDownHandler = this.keyDownHandler.bind(this)
    this.customInputHandler = this.inputHandler.bind(this)
    document.addEventListener("keydown", this.customKeyDownHandler, true)
    document.addEventListener("input", this.customInputHandler, true)
  }

  removeCustomEventHandlers() {
    document.removeEventListener("keydown", this.customKeyDownHandler)
    document.removeEventListener("input", this.customInputHandler)
  }

  initAddressSelect() {
    const self = this

    this.selectField.select2({
      dropdownParent: self.element, // attach select2 to at least its grandparent element to prevent the ambiguous selector issue
      selectOnClose: false, // disable selectOnClose because it causes too many side effects.
      tokenSeparators: null, // disable select2 built-in tokenSeparators because it does not work as we need
      tags: true,
      theme: "bootstrap4 composer",
      minimumInputLength: 1,
      createTag: function (params) {
        // Create a new option if we don't have the search term, which is a valid email, in our database

        // Don't create new tag if the search term is not a valid email
        // Email validation regex is converted from EmailValidator::VALID_EMAIL_PATTERN
        if (!isMatchEmailPatternRegex(params.term, self.validEmailPatternObjectValue)) {
          return null
        }

        // Don't create new tag if we saved the search term in our database, which is returned as remote data
        if (isSelect2SelectedOption(self.remoteDataResults, params.term)) {
          return null
        }

        // Don't create new tag if the search term matches a selected email
        if (isSelect2SelectedOption(self.selectedOptions, params.term)) {
          return null
        }

        return self.newSelectedOptionData(params.term)
      },
      ajax: {
        url: self.searchAllContactsUrlValue,
        dataType: "json",
        delay: 500,
        data: function (params) {
          return {
            q: params.term
          }
        },
        processResults: function (data, params) {
          let results = data

          // Filter out the remote search result's emails being duplicated with the current selected emails.
          const selectedEmails = self.selectField.select2("data").map((selectedOption) => {
            const email = getEmailFromSelect2SelectedOption(selectedOption)
            return email.toLowerCase()
          })

          if (selectedEmails.length > 0 && data.length > 0) {
            results = data.filter((item) => {
              return !selectedEmails.includes(item.text)
            })
          }

          // The "data" argument is an array containing results from the ajax response.
          // We need to store the "data" value to remoteDataResults, however we need to
          // clone it because select2 will directly mutate the "data" variable.
          self.remoteDataResults = [...results]

          return {
            results: results
          }
        }
      },
      templateResult: function (ajaxResult) {
        if (ajaxResult.loading) {
          return ajaxResult.text
        } else {
          // Since `templateResult` is used both for displaying the results from ajax response and select2's `createTag`,
          // only returning the full HTML from backend is not enough for `createTag`.
          return $(`
                    <div class="p-2">
                      ${escape(ajaxResult.display_name)}
                      <br>
                      <small>${escape(ajaxResult.text)}</small>
                    </div>
                  `)
        }
      },
      escapeMarkup: function (m) {
        // return the whole HTML markup instead of stripping it as the default logic of select2
        return m
      },
      templateSelection: function (option) {
        // what will be shown in the recipient input field
        return getEmailFromSelect2SelectedOption(option)
      },
      language: {
        inputTooShort: function () {
          // Close the dropdown right after the warning is triggered to prevent
          // `Please enter ${minimumInputLength} or more characters` message from being displayed.
          self.closeSelect2Dropdown()
        }
      }
    })

    // Reselect recipients on the recipient input field after applying contacts on the recipient modal
    this.selectedRecipientsValue.forEach((email) => {
      this.addSelectedOption(email)
    })

    // Create a new Email::MessageEmailAddress record after selecting an email
    this.selectField.on("select2:select", function (e) {
      const { text } = e.params.data

      Rails.ajax({
        url: self.addAddressUrlValue,
        type: "POST",
        data: new URLSearchParams({
          email: text,
          address_type: self.addressTypeValue
        }).toString(),
        success: () => {
          window.dispatchEvent(new CustomEvent("emailComposerCheckSendable"));
        }
      })
    })

    this.selectField.on("select2:unselecting", function (e) {
      if (e.params.args.originalEvent?.currentTarget.nodeName === "LI") {
        // Disable select2's "feature" of removing existed item after clicking on a dropdown item, which has the `LI` class
        e.preventDefault()

        self.closeSelect2Dropdown()
      }
    })

    // remove an Email::MessageEmailAddress record after unselecting an email
    this.selectField.on("select2:unselect", function (e) {
      // Only remove non-duplicate address
      if (e.params.data.element) {
        // Email data presents on input field. Text data presents on custom option append
        const email = getEmailFromSelect2SelectedOption(e.params.data)

        // We have to manually remove the selected option because `select2:unselect` does not remove it.
        const existedOption = self.selectField.find(`option[value='${email}']`)
        if (existedOption.length > 0) {
          existedOption.remove()
          self.selectField.trigger("change")
        }

        Rails.ajax({
          url: self.removeAddressUrlValue,
          type: "DELETE",
          data: new URLSearchParams({
            email: email,
            address_type: self.addressTypeValue
          }).toString(),
          success: () => {
            window.dispatchEvent(new CustomEvent("emailComposerCheckSendable"));
          }
        })
      }
    })
  }
}
