import ApplicationController from "./application_controller"

// Implement temporary card controller before migrating original CardController
export default class extends ApplicationController {
  // Call original CardController openCardClicked due to the original binds to $(document),
  // which prevents e.stopPropagation() from working
  openCard(event) {
    window.cardController.openCardClicked(event)
  }
}
