// Controller to copy text to Clipboard

// text can come from different places:

// - Url (to lazy load from server)
// - From html element
// - from data attribute

// Html element example:

// .btn.btn-sm.btn-secondary.mb-2{data: { controller: "copy-to-clipboard", "copy-to-clipboard-plain-text-target-value": ".js-pitch-body"}}
//   .copy-text{data: { "copy-to-clipboard-target": "copyText", action: "click->copy-to-clipboard#copyHandler" }}
//     Copy to clipboard
//     = inline_svg "svg/mdi/clipboard-arrow-left", class: "ml-1 color-white", height: 16, width: 16
//   .copy-success-text.d-none{data: { "copy-to-clipboard-target": "copySuccessText" }}
//     Copied!
//     = inline_svg "svg/mdi/clipboard-check", class: "ml-1 color-white", height: 16, width: 16
//   .copy-error-text.d-none{data: { "copy-to-clipboard-target": "copyErrorText" }}
//     Error copying to clipboard :(



// From data attribute example:

// .btn.btn-sm.btn-secondary.mb-2{data: { controller: "copy-to-clipboard", "copy-to-clipboard-plain-text-value": text_value, "copy-to-clipboard-html-text-value": html_value}}
//   .copy-text{data: { "copy-to-clipboard-target": "copyText", action: "click->copy-to-clipboard#copyHandler" }}
//     Copy to clipboard
//     = inline_svg "svg/mdi/clipboard-arrow-left", class: "ml-1 color-white", height: 16, width: 16
//   .copy-success-text.d-none{data: { "copy-to-clipboard-target": "copySuccessText" }}
//     Copied!
//     = inline_svg "svg/mdi/clipboard-check", class: "ml-1 color-white", height: 16, width: 16
//   .copy-error-text.d-none{data: { "copy-to-clipboard-target": "copyErrorText" }}
//     Error copying to clipboard :(



// Lazy loading from URL example:

// .text-secondary{data: { controller: "copy-to-clipboard", "copy-to-clipboard-url-value": copy_to_clipboard_selected_ship_items_path(segment)}}
//   .copy-text.cursor-pointer{data: { "copy-to-clipboard-target": "copyText", action: "click->copy-to-clipboard#copyHandler" }}
//     = inline_svg "svg/mdi/clipboard-arrow-left.svg", class: "icon-sm color-secondary"
//     Copy to Clipboard
//   .copy-success-text.d-none{data: { "copy-to-clipboard-target": "copySuccessText" }}
//     = inline_svg "svg/mdi/clipboard-arrow-left.svg", class: "icon-sm color-secondary"
//     Copy to Clipboard
//     %span.badge.badge-primary Copied!
//   .btn.btn-link.p-0.disabled.copy-loading-text.d-none{data: { "copy-to-clipboard-target": "copyLoadingText" }}
//     = inline_svg "svg/mdi/clipboard-arrow-left.svg", class: "icon-sm color-secondary"
//     Copy to Clipboard
//     %span.badge.badge-secondary preparing
//   .copy-error-text.d-none{data: { "copy-to-clipboard-target": "copyErrorText" }}
//     = inline_svg "svg/mdi/clipboard-arrow-left.svg", class: "icon-sm color-secondary"
//     Copy to Clipboard
//     %span.badge.badge-danger Could not copy :(

import ApplicationController from "./application_controller"
import * as clipboard from "clipboard-polyfill";


export default class extends ApplicationController {
  static values = {
    plainText: String,
    htmlText: String,
    htmlTextTarget: String,
    plainTextTarget: String,
    url: String
  }

  static targets = [
    "copyText",
    "copySuccessText",
    "copyLoadingText",
    "copyErrorText"
  ]

  connect() {
    this.lazyLoadTextToCopy();
  }

  disconnect() {
  }

  async copyHandler(){
    try {
      this.loadTextToCopy();
      await this.copyToClipboard();
      this.showSuccessState();
      this.resetToDefaultStateLater();
    } catch (error) {
      this.showErrorState();
      this.resetToDefaultStateLater();
    }
  }

  lazyLoadTextToCopy(){
    if(this.urlValue){
      this.showLoadingState();
      fetch(this.urlValue)
        .then(response => response.json())
        .then(data => {
          this.html = data.html;
          this.text = data.text;
          this.showDefaultState();
        })
    }
  }

  loadTextToCopy(){
    if(this.htmlTextTargetValue){
      this.html = document.querySelector(this.htmlTextTargetValue).innerHTML;
      this.text = document.querySelector(this.htmlTextTargetValue).innerText; // Set innerText as fallback
    }

    if(this.plainTextTargetValue){
      this.text = document.querySelector(this.plainTextTargetValue).value;
    }

    if(this.htmlTextValue){
      this.html = this.htmlTextValue;
    }

    if(this.plainTextValue){
      this.text = this.plainTextValue;
    }
  }

  async copyToClipboard(){
    var data = [];

    if(this.html){
      data["text/html"] = new Blob(
        [this.html],
        { type: "text/html" }
      );
    }
    data["text/plain"] = new Blob(
      [this.text],
      { type: "text/plain" }
    );

    const item = new clipboard.ClipboardItem(data);
    await clipboard.write([item]);
  }

  resetToDefaultStateLater(){
    window.setTimeout(function() {
      this.showDefaultState();
    }.bind(this), 3000);
  }

  showLoadingState(){
    if(this.hasCopyLoadingTextTarget){
      this.copyLoadingTextTarget.classList.remove("d-none")
      this.copyTextTarget.classList.add("d-none")
    }
  }

  showSuccessState(){
    this.copySuccessTextTarget.classList.remove("d-none")
    this.copyTextTarget.classList.add("d-none")
    if(this.hasCopyLoadingTextTarget){
      this.copyLoadingTextTarget.classList.add("d-none")
    }
  }

  showErrorState(){
    this.copyErrorTextTarget.classList.remove("d-none")
    this.copyTextTarget.classList.add("d-none")
    if(this.hasCopyLoadingTextTarget){
      this.copyLoadingTextTarget.classList.add("d-none")
    }
  }

  showDefaultState(){
    this.copyErrorTextTarget.classList.add("d-none")
    this.copySuccessTextTarget.classList.add("d-none")
    this.copyTextTarget.classList.remove("d-none")
    if(this.hasCopyLoadingTextTarget){
      this.copyLoadingTextTarget.classList.add("d-none")
    }
  }
}
