import Rails from "@rails/ujs"
import ApplicationController from "./application_controller"
import { controllerExpose } from "../utils/stimulus_utils"

export default class extends ApplicationController {
  static targets = [
    "form",
    "subjectField",
    "squireEditor",
    "contentEditableDiv",
    "contentHiddenField",
    "sendButton",
  ]

  static values = {
    formUrl: String,
    formValidateUrl: String,
    contentCursorPosition: Number,
  }

  connect() {
    controllerExpose(this)

    this.initEditableDivDrop()
    this.sendableCheck()
    this.resetEmailMessageView()

    this.throttleValidateForm = window.utils.throttle(
      this.sendableCheck.bind(this),
      500
    )

    this.sendableCheck = this.sendableCheck.bind(this)

    // set up event listener for custom event that gets triggered to check sent status
    window.addEventListener("emailComposerCheckSendable", this.sendableCheck);
    // set up event listener for custom event that gets triggered to add content
    window.addEventListener("emailComposerInsertContent", (event) => {
      const additionalContent = event.detail?.additionalContent;
      if (additionalContent) {
        this.insertAdditionalContent(additionalContent);
      }
    });

    setTimeout(() => {
      this.squireEditorTarget["stimulus-squire-editor"].editor.addEventListener(
        "input",
        this.throttleValidateForm
      )
    }, 10)
  }

  disconnect() {
    this.squireEditorTarget[
      "stimulus-squire-editor"
    ].editor.removeEventListener("input", this.throttleValidateForm)
  }

  initEditableDivDrop() {
    this.contentEditableDivTarget.addEventListener("dragover", (e) => {
      e.preventDefault()
    })

    this.contentEditableDivTarget.addEventListener("drop", (e) => {
      if (e.dataTransfer.files.length === 0) {
        return
      }

      const attachmentDropzone = $(".js-attachment-uploader")[0]["stimulus-attachment-uploads"].attachmentDropzone

      Array.from(e.dataTransfer.files).forEach((file) => {
        attachmentDropzone.addFile(file)
      })
    })
  }

  save() {
    return new Promise((resolve, reject) =>
      Rails.ajax({
        url: this.formUrlValue,
        type: "PATCH",
        data: new FormData(this.formTarget),
        success: (data) => {
          resolve(data)
        },
        error: (error) => {
          reject(error)
        }
      })
    )
  }

  sendableCheck() {
    if (this.squireEditorTarget["stimulus-squire-editor"]) {
      this.copySquireContentToHiddenField()
    }

    Rails.ajax({
      url: this.formValidateUrlValue,
      type: "POST",
      data: new FormData(this.formTarget),
      success: (data) => {
        const { sendable } = data
        if (sendable) {
          this.sendButtonTarget.classList.remove("disabled")
          this.sendButtonTarget.disabled = ""
        } else {
          this.sendButtonTarget.classList.add("disabled")
          this.sendButtonTarget.disabled = "disabled"
        }
      }
    })
  }

  resetEmailMessageView(){
    const elem = document.getElementById("message");
    if (elem) {
      elem.innerHTML = "";
      elem.setAttribute("src", "");
    }
  }

  async removeShip(event) {
    const { url } = event.currentTarget.dataset

    await new Promise((resolve, reject) =>
      Rails.ajax({
        url: url,
        type: "DELETE",
        success: (data) => {
          $(`[data-model-id="${data.tagged_ship_id}"]`).remove() // Remove tagged_ship in incoming_message container
          resolve()
        },
        error: (error) => {
          reject(error)
        }
      })
    )

    $(".js-outgoing-message-builder-selected-ships").trigger("reload")
  }

  handleBeforeSubmit() {
    this.copySquireContentToHiddenField()
  }

  checkSubjectAndBodyBeforeSending(e) {
    // Since we are hooking into the whole form's ajax:beforeSend,
    // changing smtp endpoint or delete outline attachments will also trigger the event.
    // We need to check if the source event target is not the "send button" or the "form", then we will return.
    // Note: Do not replace e.target with e.currentTarget since e.currentTarget is always this.formTarget.
    if (![this.formTarget, this.sendButtonTarget].includes(e.target)) {
      return
    }

    const subjectField = this.subjectFieldTarget
    const contentDiv = this.contentEditableDivTarget

    const isSubjectBlank = subjectField.value.length === 0
    const isContentBlank = contentDiv.innerText.trim().length === 0 &&
      contentDiv.querySelectorAll("img, quote, code, ul, li").length === 0

    let message
    if (isSubjectBlank && isContentBlank) {
      message = "Missing email subject and email body. Do you want to send this message?"
    } else if (isSubjectBlank) {
      message = "Missing email subject. Do you want to send this message?"
    } else if (isContentBlank) {
      message = "Missing email body. Do you want to send this message?"
    }

    if (message) {
      const confirmSubmit = window.confirm(message)

      if (!confirmSubmit) {
        // Do not submit if the user does not confirm
        e.preventDefault()
      }
    }
  }

  copySquireContentToHiddenField() {
    const squireEditor = this.squireEditorTarget["stimulus-squire-editor"].editor
    this.contentHiddenFieldTarget.value = squireEditor.getHTML()
  }

  insertAdditionalContent(additionalContent) {
    const squireEditor = this.squireEditorTarget["stimulus-squire-editor"].editor
    squireEditor.insertHTML(additionalContent)

    // Immediately save the text editor's content after the additional contents are added.
    // This action's purpose is to attach the inserted inline attachments to the ActionText content,
    // preventing the inline attachments from being purged when the inserted contents' original record is deleted.
    this.copySquireContentToHiddenField()
    this.save()
  }

  closeCard(){
    if (confirm("Are you sure you want to close without saving your draft?")) {
      window.cardController.closeCard();
    }
  }
}
