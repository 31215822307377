import { Controller } from "@hotwired/stimulus"

const upKey = 38
const downKey = 40

const navigationKeys = [upKey, downKey]

export default class extends Controller {
  static classes = [ "current" ]
  static targets = [ "result" ]

  connect() {
    this.reset(null)
  }

  disconnect() {
    this.reset(null)
  }

  reset(autocompleteController) {
    // A bit of not so pretty state management, but we need to
    // keep track of the controller that is actively using this
    // as an outlet
    this.currentAutocompleteController = autocompleteController

    this.element.innerHTML = ""
    this.element.classList.add("hidden")
    this.currentResultIndex = 0
    this.selectCurrentResult()
  }

  // To be called from autocomplete controller
  setHtml(html) {
    this.element.innerHTML = html
  }

  setPosition(elem) {
    const position = this.offset(elem)
    this.element.style.position = "absolute";
    this.element.style.left = `${position.left}px`;
    this.element.style.width = `${position.width}px`;
    this.element.style.top = `${position.bottom}px`;
    this.element.classList.remove("hidden");
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var left = rect.left + scrollLeft;
    var right = rect.right + scrollLeft;

    return { top: rect.top + scrollTop, left: left, bottom: rect.bottom + scrollTop, width: right-left }
  }

  navigateResults(event) {

    if(!navigationKeys.includes(event.keyCode)) {
      // No specific navigation keyCode.
      // Assume typing, and select the first result if any
      return
    }


    event.preventDefault()

    switch(event.keyCode) {
      case downKey:
        this.selectNextResult()
        break;
      case upKey:
        this.selectPreviousResult()
        break;
    }
  }

  selectFirstResult() {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle(this.currentClass, index == 0)
    })
  }

  // When clicked
  resultSelected(event) {
    this.setCurrentSelectedElement(event.currentTarget)

    this.currentAutocompleteController.autocompleted()
  }

  setCurrentSelectedElement(elementToSelect) {
    // Just set the correct currentResultIndex based on the clicked element
    this.resultTargets.forEach((element, index) => {
      if(elementToSelect == element) {
        this.currentResultIndex = index
      }
    })
  }


  selectedResultElement() {
    return this.resultTargets[this.currentResultIndex]
  }
  selectedResultValue() {
    return this.selectedResultElement()?.innerText || ""
  }

  closeResultsIfInputNoLongerInFocus() {
    if(this.currentAutocompleteController?.queryTarget !== document.activeElement) {
      this.reset(null)
    }
  }

  // private

  selectCurrentResult() {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle(this.currentClass, index == this.currentResultIndex)
    })
  }

  selectNextResult() {
    if(this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++
      this.selectCurrentResult()
    }
  }

  selectPreviousResult() {
    if(this.currentResultIndex > 0) {
      this.currentResultIndex--
      this.selectCurrentResult()
    }
  }

}
