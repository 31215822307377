import ApplicationController from "./application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
  static values = {
    url: String,
    id: String,
    seenAt: String
  }

  connect() {
    if(this.idValue != "" && this.seenAtValue == ""){
      const data = new FormData();
      data.append("id" , this.idValue);

      Rails.ajax({
        type: "POST",
        url: this.urlValue,
        data: data,
        success: this.dispatchUpdateEvent
      })
    }
  }

  dispatchUpdateEvent(){
    const event = new CustomEvent("update-mention-count")
    window.dispatchEvent(event)
  }
}
