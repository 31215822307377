import { AttachmentUpload } from "@rails/actiontext/app/javascript/actiontext/attachment_upload"
import Squire from "squire-rte/build/squire-raw"
import SquireToolbar from "./toolbar"
import {
  squirePreStyle,
  squireCodeStyle,
  editorClasses,
  acceptFileTypes
} from "./constants"

export default class SquireEditor {
  constructor(
    toolbarTarget,
    buttonTargets,
    contentTarget,
    hiddenContentTarget,
    urlDialogTarget,
    urlInputTarget,
    disableHandleDropValue
  ) {
    this.toolbarTarget = toolbarTarget
    this.buttonTargets = buttonTargets
    this.contentTarget = contentTarget
    this.hiddenContentTarget = hiddenContentTarget
    this.urlDialogTarget = urlDialogTarget
    this.urlInputTarget = urlInputTarget
    this.disableHandleDropValue = disableHandleDropValue
    this.alertDisplayed = false

    this.initSquireEditor()
    this.restoreSavedContent()
    this.initToolbar()
    this.setupEventListeners()
    this.initDrag()
    this.initDrop()
    this.initPaste()
  }

  initSquireEditor() {
    this.editor = new Squire(this.contentTarget, {
      tagAttributes: {
        ul: { class: "UL" },
        ol: { class: "OL" },
        li: { class: "listItem" },
        a: { target: "_blank" },
        pre: { style: squirePreStyle },
        code: { style: squireCodeStyle }
      }
    })
  }

  restoreSavedContent() {
    this.editor.setHTML(this.hiddenContentTarget.value)
  }

  initToolbar() {
    this.toolbar = new SquireToolbar(
      this.toolbarTarget,
      this.buttonTargets,
      this.urlDialogTarget,
      this.urlInputTarget,
      this
    )
  }

  destroy() {
    this.editor.destroy()
  }

  setupEventListeners() {
    this.contentTarget.addEventListener("blur", () => {
      this.toolbar.blurAllButtons()
      this.hiddenContentTarget.value = this.editor.getHTML()
    })

    this.contentTarget.addEventListener("focus", () => {
      this.contentTarget.classList.remove(editorClasses.draggingActiveClass)
      this.toolbar.resetButtonsStyle()
    })

    this.editor.addEventListener("pathChange", () => {
      this.toolbar.resetButtonsStyle()
    })
    this.editor.addEventListener("select", () => {
      this.toolbar.resetButtonsStyle()
    })

    this.editor.addEventListener("undoStateChange", ({ canUndo, canRedo }) => {
      this.toolbar.toggleDisabled(this.toolbar.buttons["undo"], canUndo)
      this.toolbar.toggleDisabled(this.toolbar.buttons["redo"], canRedo)
    })
  }

  initDrag() {
    this.editor.addEventListener("dragenter", (e) => {
      this.contentTarget.classList.add(editorClasses.draggingActiveClass)

      // This is required to make drop event work
      e.preventDefault()
    })

    this.editor.addEventListener("dragleave", (e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.contentTarget.classList.remove(editorClasses.draggingActiveClass)
      }
    })
  }

  initDrop() {
    this.editor.addEventListener("drop", (e) => {
      this.alertDisplayed = false
      this.contentTarget.classList.remove(editorClasses.draggingActiveClass)
      if (!this.disableHandleDropValue) {
        if (!e.dataTransfer?.files.length) {
          return
        }

        // TODO: Handle drop multiple files
        const file = e.dataTransfer.files[0]

        this.uploadInlineAttachment(file, this.contentTarget)
      }
    })
  }

  initPaste() {
    function extractAllNodes(node, nodeName) {
      const result = []

      // If not match, find in its children
      if (node.nodeName !== nodeName) {
        [...node.children].forEach((child) =>
          result.push(...extractAllNodes(child, nodeName))
        )
      } else {
        result.push(node)
      }
      return result
    }

    function htmlToElement(html) {
      var template = document.createElement("template")
      html = html.trim() // Never return a text node of whitespace as the result
      template.innerHTML = html
      return template.content.firstChild
    }

    function cloneAttributes(target, source) {
      [...source.attributes].forEach((attr) => {
        target.setAttribute(attr.nodeName, attr.nodeValue)
      })
    }

    this.editor.addEventListener("paste", (e) => {
      this.alertDisplayed = false

      if (!e.clipboardData?.files.length) {
        return
      }

      // TODO: Handle drop multiple files
      const file = e.clipboardData.files[0]

      this.uploadInlineAttachment(file, this.contentTarget)
    })

    this.editor.addEventListener("willPaste", async (e) => {
      // Handle base64 image paste
      const allImgNodes = []
      const imgNodeName = "IMG"

      // We need to convert HTMLCollection to Array
      Array.from(e.fragment.children).forEach((child) => {
        allImgNodes.push(...extractAllNodes(child, imgNodeName))
      })

      for (let index = 0; index < allImgNodes.length; index++) {
        const imgNode = allImgNodes[index]
        if (
          imgNode.src.startsWith("data:image") &&
          imgNode.src.includes(";base64,")
        ) {
          const url = imgNode.src

          // Begin extract blob data from base64 image
          const blobResponse = await fetch(url)
          const blob = await blobResponse.blob()

          // Extract file type and file extension
          const fileType = imgNode.src.split(";")[0].split(":")[1]
          const ext = fileType.split("/")[1]

          // Create file
          const file = new File([blob], `base64Image-${index}.${ext}`, {
            type: fileType
          })

          // Upload file, clone attributes of new image tag to existing image node
          this.uploadInlineAttachment(
            file,
            this.contentTarget,
            ({ status, data }) => {
              if (status) {
                cloneAttributes(imgNode, htmlToElement(data))
              } else {
                imgNode.remove()
              }
            }
          )
        }
      }
    })
  }

  uploadInlineAttachment(file, element, callback) {
    // Start handle inline attachment upload
    // Validate file size & file type
    if (!this.isValidInlineAttachment(file)) {
      if (this.alertDisplayed === false) {
        window.alert("Please only attach png, jpeg, jpg or gif files <= 4 Megabytes!")
      }
      if (typeof callback === "function") {
        callback({ status: false })
      }
      this.alertDisplayed = true
      return
    }

    const attachment = {
      file,
      setUploadProgress: (progress) => {
        // TODO: Handle image upload progress
        // This function must present to be called by ActionText AttachmentUpload instance
        // console.log(progress)
      },
      setAttributes: ({ sgid, url }) => {
        // setAttributes() will be called after AttachmentUpload finishes file uploading
        // sgid is SignedGlobalID generated by Rails
        // url is the generated url of the attachment

        // Mimic ActionText Figure tag
        const imageTag = `<img src=${url} data-trix-attachment='{\"sgid\":"${sgid}",\"contentType\":\"${file.type}\",\"url\":\"${url}\",\"filename\":\"${file.name}\",\"filesize\":${file.size},\"previewable\":true}' data-trix-attributes='{\"presentation\":\"gallery\"}' />`

        if (typeof callback === "function") {
          callback({ status: true, data: imageTag })
        } else {
          this.editor.insertHTML(imageTag)
        }

        // Save the editor's content into the hidden input field to get ready for the submit button
        this.hiddenContentTarget.value = this.editor.getHTML()
      }
    }

    const upload = new AttachmentUpload(attachment, element)
    upload.start()
  }

  isValidInlineAttachment(file) {
    const maxFileSize = 4 * 1024 * 1024 // 4 Megabytes
    return acceptFileTypes.includes(file.type) && file.size <= maxFileSize
  }

  get isLinkPresence() {
    return />A\b/.test(this.editor.getPath())
  }

  get isQuotePresence() {
    return (
      this.editor.hasFormat("blockquote") ||
      /BLOCKQUOTE\b/.test(this.editor.getPath())
    )
  }

  get isUnorderedList() {
    return this.editor.hasFormat("UL") || />UL\b/.test(this.editor.getPath())
  }

  get isOrderedList() {
    return this.editor.hasFormat("OL") || />OL\b/.test(this.editor.getPath())
  }

  get currentAlignment() {
    const range = this.editor.getSelection()

    if (
      range.startContainer !== this.contentTarget &&
      range.endContainer.contains(range.startContainer)
    ) {
      const nodeName = range.startContainer.nodeName
      const nodeBeGetStyle = nodeName === "#text" ? range.startContainer.parentNode : range.startContainer
      return window.getComputedStyle(nodeBeGetStyle).textAlign
    } else {
      const rawRangeChildren = range.cloneContents().children
      const rangeChildren = []

      const itemsWithBlockQuoteChildren = (child) => {
        const result = []

        // If we meet a block quote, we need to check alignment of its children
        if (child.nodeName === "BLOCKQUOTE") {
          [...child.children].forEach((nestedChild) =>
            result.push(...itemsWithBlockQuoteChildren(nestedChild))
          )
        } else {
          result.push(child)
        }
        return result
      }

      // We need to convert HTMLCollection to Array
      [...rawRangeChildren].forEach((child) => {
        rangeChildren.push(...itemsWithBlockQuoteChildren(child))
      })

      const childrenAlignments = rangeChildren.map(
        (node) => node.style["text-align"] // TODO: Find a better way
      )
      const uniqueAlignments = [...new Set(childrenAlignments)]

      if (
        uniqueAlignments.length === 2 &&
        uniqueAlignments.includes("") &&
        uniqueAlignments.includes("left")
      ) {
        return "left"
      } else if (uniqueAlignments.length === 1) {
        return uniqueAlignments[0] || "left"
      }
    }

    return null
  }

  get isEditorActive() {
    return document.activeElement === this.contentTarget
  }
}
