// Calling controllerExpose() inside a stimulus controller creates a reference to the current instance of that controller.
// We can access that controller's instance through a HTML DOM element query.

// An example with "circular_build_controller.js"
// On the DOM, "circular-build-controller" is attached to the element having ".js-circular-builder-wrapper" class.

// From the outside of circular_build_controller.js, we can call its insertAdditionalContent() (defined inside of it) after calling controllerExpose()
// For example: document.querySelector(".js-circular-builder-wrapper")["stimulus-circular-build"].insertAdditionalContent()

export function controllerExpose(stimulusController) {
  stimulusController.element["stimulus-" + stimulusController.identifier] = stimulusController // Create a reference to the Stimulus controller instance
}
