/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails"
// Disable Turbo Drive for now (we still rely on page load events for some things)
Turbo.session.drive = false

// Import Rails UJS and expose globally
import Rails from "@rails/ujs"
Rails.start()
window.Rails = Rails

// Although DOMPurify is not explicitly be called in our project, it is called in Squire source code
// to sanitize inserted HTML from users instead of Squire editor built-in sanitizer, which caused visual bugs
// when pasting complex HTML contents.
import DOMPurify from 'dompurify'
window.DOMPurify = DOMPurify

// require("channels")
// We don't require channels the default Rails way, but requiring them directly
// in controllers instead. Like:
//
// import consumer from "../channels/consumer"


// Expose jQuery globally (since we use jQuery in JavaScript responses)
import jQuery from "jquery"
window.$ = jQuery
window.jQuery = jQuery

// Set up localtime
import LocalTime from "local-time"
LocalTime.start()

// Set up Bootstrap's JS
import "bootstrap"
// And the colorpicker
import "bootstrap-colorpicker"

import Chart from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.plugins.register(annotationPlugin)

import "select2"

require("@rails/actiontext")

// Utils
import "../utils/utils"
import "../utils/dragster"

// Config
import "../configuration"

// App-specific Controllers (deprecated way of writing)
import "../controllers/advanced_search_form_controller"
import "../controllers/autopad_fields_controller"
import "../controllers/beforeunload"
import "../controllers/big_dropdown"
import "../controllers/card_controller"
import "../controllers/card_uploads_controller"
import "../controllers/chart_controller"
import "../controllers/color_picker_controller"
import "../controllers/draggable_controller"
import "../controllers/inline_editable"
import "../controllers/lazy_load_controller"
import "../controllers/load_more_controller"
import "../controllers/multi_select"
import "../controllers/page_controller"
import "../controllers/pitch_controller"
import "../controllers/resizable_grid_fields_controller"
import "../controllers/selected_items_controller"
import "../controllers/tr_form_controller"
import "../controllers/tr_form_punch_valuations"
import "../controllers/tracking"

// Default way of writing JS controllers:
// Stimulus
import "../stimulus_controllers"


// Collapse Navbar on front page
var navbarCollapse = function() {
  var $navbar = $(".js-navbar")
  var offset = $navbar.offset()

  // Don't evaluate more code if no offset is found
  if(!offset) {
    return
  }

  if ($navbar.offset().top > $navbar.data("offset")) {
    $(".js-navbar").addClass("navbar-shrink")
  } else {
    $(".js-navbar").removeClass("navbar-shrink")
  }
}
// Collapse now if page is not at top
$(document).on("load", navbarCollapse)
// Collapse the navbar when page is scrolled
$(window).on("scroll", navbarCollapse)

// Use general bootstrap tooltip selector (that uses event delegation :D )
$(document).tooltip({
  selector: "[data-toggle=tooltip]"
})
