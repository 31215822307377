import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  static values = {
    markAsSeenUrl: String,
    searchUrl: String
  }
  static targets = [ "textArea" ]

  connect() {
    this.initializeTribute()
  }


  initializeTribute() {
    var markAsSeenUrl = this.markAsSeenUrlValue

    this.tribute = new Tribute({
      noMatchTemplate: "",
      allowSpaces: true,
      requireLeadingSpace: false,
      lookup: 'name',
      values: this.fetchUsers.bind(this),
        selectTemplate: function(item) {
          return '@' + item.original.slug;
        }
    })
    this.tribute.attach(this.textAreaTarget)
  }

  fetchUsers(text, callback) {
    const encodedText = encodeURIComponent(text);
    fetch(`${this.searchUrlValue}?query=${encodedText}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }
}
