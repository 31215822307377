import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  keyDown(e) {
    const ctrlOrCmdKey = e.ctrlKey || e.metaKey

    if (ctrlOrCmdKey && e.keyCode === 13) {
      // Find parent form, and submit it
      const form = e.currentTarget.closest("form")

      if (form.dataset.remote) {
        // Submit event is not safe with Rails UJS, so need to use Rails.fire for remote forms
        Rails.fire(form, "submit")
      } else {
        form.dispatchEvent(new Event("submit"))
      }
    }
  }
}
