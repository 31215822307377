import ApplicationController from "./application_controller"
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = ["customFormPlaceholder", "customFieldName"]

  static values = {
    reloadUrl: String
  }

  connect() {
    this.loadCustomForm()
  }

  loadCustomForm() {
    if (!this.hasCustomFormPlaceholderTarget) {
      return
    }

    const self = this

    Rails.ajax({
      url: self.reloadUrlValue,
      type: "GET",
      data: new URLSearchParams({ custom_search_field: self.customFieldNameTarget.value }).toString(),
      success: (html) => {
        self.customFormPlaceholderTarget.innerHTML = html.body.innerHTML
      }
    })
  }
}
