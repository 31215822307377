import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["list", "recipientsText"]
  static values = {
    loadAllRecipientsUrl: String
  }

  connect() {
    this.checkListHeight()
  }

  checkListHeight() {
    // If height of recipient list is over 130px ("130px" is defined in "email-circulars.scss")
    // Display 2 classes "gradient" and "expand-icons" to make list expandable

    let containerHeight = this.listTarget.offsetHeight
    if (containerHeight >= 130) {
      document.querySelector(".gradient").classList.remove("d-none")
      document.querySelector(".expand-icons").classList.remove("d-none")
    }
  }

  loadAllRecipients(){
    fetch(this.loadAllRecipientsUrlValue)
      .then(response => {
        return response.text();
      })
      .then(html => {
        this.recipientsTextTarget.innerHTML = html;
      });
  }

  expandList() {
    this.recipientsTextTarget.innerText = this.recipientsTextTarget.innerText + "... loading all recipients ..."
    this.loadAllRecipients()
    this.element.classList.add("expanded")
  }

  collapseList() {
    this.element.classList.remove("expanded")
  }
}
