import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  // Purchase enquiry form sprinkle.
  // Whenever using the dropdown for segment, reload the card HTML with an updated form
  reload(e) {
    const $selectElement = $(e.currentTarget)
    const $form = $selectElement.parents("form")
    // Avoid serializing authenticity_token for just a get request
    const $inputs = $form.find(":input[name!=authenticity_token]")

    $.get($form.data("reload-url"), $inputs.serialize(), function (html) {
      window.cardController.cardContent(html)
    })
  }
}
