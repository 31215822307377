# Punch valuation specific event handlers
#
submitSelector = ".js-tr-form-submit"

# Event handler to up all the "destination" fields using value from the "source"
# field
valuationFormEventHandlers = (triggerElement, source, destination, callback) ->
  $(document).on("click", triggerElement, () ->
    newValue = $(source).val()
    return unless newValue

    $(destination).val(newValue)
    $(destination).trigger("change")
    return unless callback

    callback()
  )
  return

afterUpdateCallback = () ->
  $(".big-dropdown-toggle").trigger("big-dropdown:close")


# Update all the "date" fields using the dropdown on the table header
valuationFormEventHandlers(
  ".js-punch-valuation-date-all-trigger",
  ".js-punch-valuation-date-all",
  ".js-punch-valuation-date",
  afterUpdateCallback
)

# Update all the "client" fields using the dropdown on the table header
valuationFormEventHandlers(
  ".js-punch-valuation-client-all-trigger",
  ".js-punch-valuation-client-all",
  ".js-punch-valuation-client",
  afterUpdateCallback
)

# Update all the "comment" fields using the dropdown on the table header
valuationFormEventHandlers(
  ".js-punch-valuation-comment-all-trigger",
  ".js-punch-valuation-comment-all",
  ".js-punch-valuation-comment",
  afterUpdateCallback
)

# Update all the "amount_uniit" fields using the dropdown on the table header
valuationFormEventHandlers(
  ".js-punch-valuation-amount-unit-all-trigger",
  ".js-punch-valuation-amount-unit-all",
  ".js-punch-valuation-amount-unit",
  afterUpdateCallback
)

# Event listener to update the individual buttons on entering new values
$(document).on("keydown change", ".js-punch-valuation .tr-form-input", (event) ->
  $button = $(event.currentTarget).closest(".js-tr-form-row").find(submitSelector)

  $button.text($button.data("save-label"))
  $button.removeClass("btn-outline-secondary").addClass("btn-primary")
  $button.removeAttr("disabled")
)
