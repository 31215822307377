import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    this.initializeSelect2()
  }

  disconnect() {
    this.destroySelect2()
  }

  initializeSelect2() {
    const $element =  $(this.element)
    let params = {
      dropdownParent: $element.parent()[0],
      allowClear: true,
      theme: "bootstrap4",
      multiple: true
    }

    if(this.element.dataset.select2Options) {
      params = $.extend(params, JSON.parse(this.element.dataset.select2Options))
    }

    $element.select2(params)
  }

  destroySelect2() {
    $(this.element).select2("destroy")
  }
}
