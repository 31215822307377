import { editorClasses, acceptFileTypes } from "./constants"

export default class SquireToolbar {
  constructor(
    toolbarTarget,
    buttonTargets,
    urlDialogTarget,
    urlInputTarget,
    squireEditor
  ) {
    this.toolbarTarget = toolbarTarget
    this.buttonTargets = buttonTargets
    this.urlDialogTarget = urlDialogTarget
    this.urlInputTarget = urlInputTarget
    this.squireEditor = squireEditor

    this.buttons = {}
    this.fontFaceButtons = []
    this.fontSizeButtons = []
    this.initToolbarButtons()
    this.setupToolbarButtons()
  }

  initToolbarButtons() {
    this.buttonTargets.forEach((buttonTarget) => {
      const attribute = buttonTarget.dataset.squireAttribute
      this.buttons[attribute] = buttonTarget
      this.buttons[attribute].addEventListener("mousedown", (e) =>
        e.preventDefault()
      )
      if (buttonTarget.dataset.squireFontFace) {
        this.fontFaceButtons.push(buttonTarget)
      }
      if (buttonTarget.dataset.squireFontSize) {
        this.fontSizeButtons.push(buttonTarget)
      }
    })
    this.resetButtonsStyle()
  }

  toggleDisabled(element, state) {
    element.disabled = !this.squireEditor.isEditorActive || !state
  }

  toggleActive(element, active) {
    this.squireEditor.isEditorActive && active
      ? element.classList.add(editorClasses.activeClass)
      : element.classList.remove(editorClasses.activeClass)
  }

  blurAllButtons() {
    for (const button in this.buttons) {
      this.toggleActive(this.buttons[button], false)
    }
  }

  resetButtonsStyle() {
    this.toggleActive(
      this.buttons["bold"],
      this.squireEditor.editor.hasFormat("b")
    )
    this.toggleActive(
      this.buttons["italic"],
      this.squireEditor.editor.hasFormat("i")
    )
    this.toggleActive(
      this.buttons["underline"],
      this.squireEditor.editor.hasFormat("u")
    )
    this.toggleActive(
      this.buttons["subscript"],
      this.squireEditor.editor.hasFormat("sub")
    )
    this.toggleActive(
      this.buttons["superscript"],
      this.squireEditor.editor.hasFormat("sup")
    )
    this.toggleActive(
      this.buttons["openLinkDialog"],
      this.squireEditor.isLinkPresence
    )
    this.toggleActive(this.buttons["quote"], this.squireEditor.isQuotePresence)
    this.toggleActive(
      this.buttons["makeUnorderedList"],
      this.squireEditor.isUnorderedList
    )
    this.toggleActive(
      this.buttons["makeOrderedList"],
      this.squireEditor.isOrderedList
    )

    this.toggleAlignmentButtons()
  }

  toggleAlignmentButtons() {
    this.toggleActive(
      this.buttons["alignLeft"],
      this.squireEditor.currentAlignment === "left"
    )
    this.toggleActive(
      this.buttons["alignCenter"],
      this.squireEditor.currentAlignment === "center"
    )
    this.toggleActive(
      this.buttons["alignRight"],
      this.squireEditor.currentAlignment === "right"
    )
  }

  setupToolbarButtons() {
    this.buttons["bold"].addEventListener("click", () => {
      this.squireEditor.editor.hasFormat("b") ? this.squireEditor.editor.removeBold() : this.squireEditor.editor.bold()
      this.toggleActive(
        this.buttons["bold"],
        this.squireEditor.editor.hasFormat("b")
      )
    })

    this.buttons["italic"].addEventListener("click", () => {
      this.squireEditor.editor.hasFormat("i")
        ? this.squireEditor.editor.removeItalic()
        : this.squireEditor.editor.italic()
      this.toggleActive(
        this.buttons["italic"],
        this.squireEditor.editor.hasFormat("i")
      )
    })

    this.buttons["underline"].addEventListener("click", () => {
      this.squireEditor.editor.hasFormat("u")
        ? this.squireEditor.editor.removeUnderline()
        : this.squireEditor.editor.underline()
      this.toggleActive(
        this.buttons["underline"],
        this.squireEditor.editor.hasFormat("u")
      )
    })

    this.fontFaceButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault()
        this.squireEditor.editor.setFontFace(button.dataset.squireFontFace)
      })
    })

    this.fontSizeButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault()
        this.squireEditor.editor.setFontSize(button.dataset.squireFontSize)
      })
    })

    this.buttons["subscript"].addEventListener("click", () => {
      this.squireEditor.editor.hasFormat("sub")
        ? this.squireEditor.editor.removeSubscript()
        : this.squireEditor.editor.subscript()
      this.toggleActive(
        this.buttons["subscript"],
        this.squireEditor.editor.hasFormat("sub")
      )
    })

    this.buttons["superscript"].addEventListener("click", () => {
      this.squireEditor.editor.hasFormat("sup")
        ? this.squireEditor.editor.removeSuperscript()
        : this.squireEditor.editor.superscript()
      this.toggleActive(
        this.buttons["superscript"],
        this.squireEditor.editor.hasFormat("sup")
      )
    })

    this.buttons["alignLeft"].addEventListener("click", () => {
      this.squireEditor.editor.setTextAlignment("left")
      this.toggleAlignmentButtons()
    })

    this.buttons["alignCenter"].addEventListener("click", () => {
      this.squireEditor.editor.setTextAlignment("center")
      this.toggleAlignmentButtons()
    })

    this.buttons["alignRight"].addEventListener("click", () => {
      this.squireEditor.editor.setTextAlignment("right")
      this.toggleAlignmentButtons()
    })

    this.buttons["openLinkDialog"].addEventListener("click", () => {
      if (this.urlDialogTarget.classList.contains(editorClasses.activeClass)) {
        this.urlDialogTarget.classList.remove(editorClasses.activeClass)
        this.urlInputTarget.value = ""
      } else {
        this.urlDialogTarget.classList.add(editorClasses.activeClass)
        if (this.squireEditor.isLinkPresence) {
          const currentUrl = this.squireEditor.editor.getSelection()
            .commonAncestorContainer.parentNode.closest("a").href
          this.urlInputTarget.value = currentUrl
        }
        this.urlInputTarget.focus()
      }
      this.toggleActive(
        this.buttons["openLinkDialog"],
        this.urlDialogTarget.classList.contains(editorClasses.activeClass) ||
          this.squireEditor.isLinkPresence
      )
    })

    this.buttons["makeLink"].addEventListener(
      "click",
      this.makeLink.bind(this)
    )

    this.buttons["removeLink"].addEventListener("click", () => {
      this.urlInputTarget.value = ""
      this.urlDialogTarget.classList.remove(editorClasses.activeClass)
      this.urlInputTarget.removeAttribute("data-squire-validate")
      this.urlInputTarget.required = ""
      this.squireEditor.editor.removeLink()
    })

    this.urlInputTarget.addEventListener("keydown", (e) => {
      // Handle some keyboard keys when in url input field
      const ESC_KEY = 27
      const ENTER_KEY = 13
      switch (e.which) {
        case ESC_KEY:
          this.urlInputTarget.value = ""
          this.urlDialogTarget.classList.remove(editorClasses.activeClass)
          this.urlInputTarget.removeAttribute("data-squire-validate")
          this.urlInputTarget.required = ""
          this.toggleActive(
            this.buttons["openLinkDialog"],
            this.squireEditor.isLinkPresence
          )
          this.squireEditor.editor.focus()
          break
        case ENTER_KEY:
          e.preventDefault()
          e.stopImmediatePropagation()
          this.makeLink()
          break
        default:
          break
      }
    })

    this.buttons["quote"].addEventListener("click", () => {
      this.squireEditor.isQuotePresence
        ? this.squireEditor.editor.decreaseQuoteLevel()
        : this.squireEditor.editor.increaseQuoteLevel()

      this.toggleActive(
        this.buttons["quote"],
        this.squireEditor.isQuotePresence
      )
    })

    this.buttons["makeUnorderedList"].addEventListener("click", () => {
      this.squireEditor.isUnorderedList
        ? this.squireEditor.editor.removeList()
        : this.squireEditor.editor.makeUnorderedList()
    })

    this.buttons["makeOrderedList"].addEventListener("click", () => {
      this.squireEditor.isOrderedList
        ? this.squireEditor.editor.removeList()
        : this.squireEditor.editor.makeOrderedList()
    })

    this.buttons["insert"].addEventListener("click", () => {
      this.squireEditor.alertDisplayed = false
      const inputElement = document.createElement("INPUT")
      inputElement.setAttribute("type", "file")
      inputElement.setAttribute("accept", acceptFileTypes.join(","))
      inputElement.onchange = (e) => {
        const file = e.currentTarget.files[0]
        this.squireEditor.uploadInlineAttachment(
          file,
          this.squireEditor.contentTarget
        )
        inputElement.remove()
      }
      inputElement.click()
    })

    this.toggleDisabled(this.buttons["undo"], false)
    this.toggleDisabled(this.buttons["redo"], false)
    this.buttons["undo"].addEventListener("click", () => {
      this.squireEditor.editor.undo()
    })
    this.buttons["redo"].addEventListener("click", () => {
      this.squireEditor.editor.redo()
    })
  }

  makeLink() {
    try {
      const currentUrl = new URL(this.urlInputTarget.value.trim()).href
      if (this.squireEditor.isLinkPresence) {
        const closestLinkNode = this.squireEditor.editor
          .getSelection()
          .commonAncestorContainer.parentNode.closest("a")
        closestLinkNode.href = currentUrl
        this.urlInputTarget.value = ""
        this.urlDialogTarget.classList.remove(editorClasses.activeClass)
        this.urlInputTarget.required = ""
        this.urlInputTarget.removeAttribute("data-squire-validate")
        this.squireEditor.editor.focus()
      } else {
        this.urlInputTarget.value = ""
        this.urlDialogTarget.classList.remove(editorClasses.activeClass)
        this.urlInputTarget.required = ""
        this.urlInputTarget.removeAttribute("data-squire-validate")
        this.squireEditor.editor.makeLink(currentUrl)
      }
    } catch (_) {
      this.urlInputTarget.dataset.squireValidate = true
      this.urlInputTarget.required = "required"
    }
  }
}
