import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["searchBox", "contentList"]

  initialize() {
    if (this.hasContentListTarget) {
      this.contentListTarget.querySelector(".js-add-button").focus()
    } else {
      // set the pointer to end of searchBox string
      const length = this.searchBoxTarget.value.length
      this.searchBoxTarget.selectionStart = length
      this.searchBoxTarget.selectionEnd = length
      this.searchBoxTarget.focus()
    }
  }
}
