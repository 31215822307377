# Chart.js is available globally already as we used script-loader
import {observe} from "selector-observer"

#
# Expects a canvas element with class js-chart
# and JSON embedded into a data-json attribute for the chart data
#
#     %canvas.js-chart{data: {json: "{type: 'bar'}"}}
#
class ChartController

  constructor: ->
    # This is somewhat stateful, but a nice way to store charts we want to call
    # destroy() on when they are removed from the DOM
    this.charts = {}

    observe(".js-chart", add: this.drawChart)

  # Draw each and every chart, given the canvas element and JSON to initialize
  # a Chart.js instance with
  drawChart: (chartElem) =>
    $chartElem = $(chartElem)
    data = $chartElem.data("json")
    mode = $chartElem.data("mode")


    # Decorate x and y axis tick labels
    if data.type in ["bar", "horizontalBar"]
      this.decorateAxisTicksWithCallback("yAxes", data, mode)
      this.decorateAxisTicksWithCallback("xAxes", data, mode)

    # Decorate tooltips when hovering a bar
    this.decorateTooltips(data, mode)

    # Practical for outputting the data object (it gets modified later by Chartjs)
    # so we need to serialize+deserialize to display the current value in the console
    # console.log(JSON.parse(JSON.stringify(data)))
    chart = new Chart(chartElem, data)

  decorateTooltips: (data, mode) =>

    chartType = data.type
    return unless chartType in ["bar", "horizontalBar", "pie"]

    callbacks = data?.options?.tooltips?.callbacks
    # Extra labels for tooltips defined by us!
    tooltipLabelsSuffix = data?.options?.tooltips?.labelsSuffix

    if callbacks
      callbacks.label = (tooltipItem, data) ->
        if chartType in ["horizontalBar", "bar"]
          label = data.datasets[tooltipItem.datasetIndex].label || ""
        else if chartType == "pie"
          label = data.labels[tooltipItem.index]
        label += ": " if label

        labelValue = if chartType == "horizontalBar"
          tooltipItem.xLabel
        else if chartType == "bar"
          tooltipItem.yLabel
        else if chartType == "pie"
          data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem.index]
        else
          console.error("chartType #{chartType} not supported")

        # Format numbers to make them more readable
        label += utils.numberWithCommas(labelValue)

        if tooltipLabelsSuffix
          label += " #{tooltipLabelsSuffix[tooltipItem.index]}"

        return label

  decorateAxisTicksWithCallback: (axes, data, mode) =>
    # Decorate data with numberFormat function
    for a in data?.options?.scales?[axes]
      ticks = a?.ticks
      if ticks && !ticks.callback
        ticks.callback = this.styleTextOrNumberCallback(mode)

  styleTextOrNumberCallback: (mode) =>

    return (value, index, values) =>
      # Noop if value is empty/falsy
      return value unless value

      if typeof value is "number"
        this.shortNumberFormatBasedOnMaxValue(value, index, values)
      else
        labelLength = 17
        if mode == "large"
          labelLength = 40

        # Just make sure text is not too long
        return utils.truncate(value.toString(), labelLength)

  # Return
  # 3M instead of 3000000,
  # 4k instead of 4000
  # and fall back to the regular value when low enough
  shortNumberFormatBasedOnMaxValue: (value, index, values) =>

    maxValue = Math.max(values...)

    if maxValue > 2000000
      return value / 1e6 + "M";
    if maxValue > 10000
      # Note; maxValue should be compared to a low value close to 2000 as years
      # can be shortened to 2k
      return value / 1e3 + "k";

    return value

window.chartController = new ChartController()
