getElements = (event) ->
  $toggler = $(event.currentTarget)
  $container = $toggler.parents('.big-dropdown')
  $dropdown = $container.find('.big-dropdown-menu')
  [$container, $toggler, $dropdown]

setHeightOfBigDropDown = ->
  $('.big-dropdown-scroll-container').each ->
    subtractPx = $(this).data('big-dropdown-subtract-px')
    unless subtractPx
      throw new Error('you must specify data-big-dropdown-subtract-px on the big-dropdown-scroll-container element')

    maxHeight = $(window).height() - subtractPx
    containerHeight = $(this).find('.big-dropdown-container:first').outerHeight(true)
    heightToUse = if containerHeight > maxHeight then maxHeight else containerHeight
    $(this).height( heightToUse )

$(document).on 'click', '.big-dropdown-toggle', (e) ->
  e.preventDefault()
  [$container, $toggler, $dropdown] = getElements(e)
  unless $container.hasClass 'open'
    $toggler.trigger('big-dropdown:open')
  else
    $toggler.trigger('big-dropdown:close')

# If clicked inside a big dropdown menu, just close the menu
$(document).on "click", ".js-big-dropdown-close", (e) ->
  e.preventDefault()
  $container = $(e.currentTarget).closest(".big-dropdown")
  $toggler = $container.find(".big-dropdown-toggle")
  $toggler.trigger('big-dropdown:close')

# If a container is placed around a link, toggle a utility "open" class.
# Great for opening closing meny items inside big dropdown (accordion style)
$(document).on "click", ".big-dropdown-link-container .big-dropdown-link", (e) ->
  e.preventDefault()

  $linkContainer = $(e.currentTarget).closest(".big-dropdown-link-container")
  $linkContainer.toggleClass("open")
  setHeightOfBigDropDown()

# Close big dropdown on all click events outside the big dropdown listening for
# clicks on an overlay we put right behind the big dropdown menu itself
$(document).on "click", ".big-dropdown-overlay", (e) ->
  $overlay = $(e.currentTarget)
  $container = $overlay.parents(".big-dropdown")
  $toggler = $container.find(".big-dropdown-toggle")
  $toggler.trigger("big-dropdown:close")

$(document).on 'big-dropdown:open', '.big-dropdown-toggle', (e) ->
  [$container, $toggler, $dropdown] = getElements(e)

  $dropdown.addClass('open')
  $container.addClass('open')

  if $dropdown.data("offset-y")
    $dropdown.css({"margin-top": $dropdown.data("offset-y")})

  $contentContainer = $dropdown.find('.big-dropdown-container')


  # Add an overlay that will help us close the big dropdown when
  # clicking anywhere outside the big dropdown menu
  $container.prepend("<div class='big-dropdown-overlay'></div>")

  # Make it OK to add dropdown body
  $contentBody = $contentContainer.data('content-body')
  if $contentBody
    setHeightOfBigDropDown()
  else
    # use attr("data-content-url") instead of data("content-url") since data() returns cached values, and sometimes we want to update it
    $contentUrl = $contentContainer.attr("data-content-url") || throw new Error('You must specify a data-content-url on .big-dropdown-container')
    $.get $contentUrl, (response) ->
      $contentContainer.html(response)
      setHeightOfBigDropDown()

$(document).on 'big-dropdown:close', '.big-dropdown-toggle', (e) ->
  [$container, $toggler, $dropdown] = getElements(e)
  $dropdown.removeClass 'open'
  $container.removeClass 'open'
  # Now remove the overlay that was there to pick up click events outside the
  # big dropdown menu
  $container.find(".big-dropdown-overlay").remove()

$(window).on 'resize', ->
  setHeightOfBigDropDown()