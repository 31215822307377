export const squirePreStyle =
  "border-radius:3px;border:1px solid #ccc;padding:7px 10px;background:#f6f6f6;font-family:menlo,consolas,monospace;font-size:90%;white-space:pre-wrap;word-wrap:break-word;overflow-wrap:break-word;"

export const squireCodeStyle =
  "border-radius:3px;border:1px solid #ccc;padding:1px 3px;background:#f6f6f6;font-family:menlo,consolas,monospace;font-size:90%;"

export const editorClasses = {
  activeClass: "squire-active",
  draggingActiveClass: "dragging-active"
}

export const acceptFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif"
]
