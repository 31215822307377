import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["item"]
  static classes = ["hidden"]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "d-none";
  }

  toggle() {
    this.itemTargets.forEach(s => {
      s.classList.toggle(this.class);
    });
  }

  show() {
    this.itemTargets.forEach(s => {
      s.classList.remove(this.class);
    });
  }

  hide() {
    this.itemTargets.forEach(s => {
      s.classList.add(this.class);
    });
  }
}
