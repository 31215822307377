import Dropzone from "dropzone"
import { csrfTokenHeader } from "../utils/ajax_request"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["container", "previews", "trigger"]
  static values = { paramName: String, url: String }

  connect() {
    const dropzoneOptions = {
      acceptedFiles: "image/*",
      createImageThumbnails: false,
      headers: csrfTokenHeader(),
      maxFiles: 1,
      method: "put",
      paramName: this.paramNameValue,
      previewsContainer: this.previewsTarget,
      url: this.urlValue
    }

    const imageDropzone = new Dropzone(this.triggerTarget, dropzoneOptions)

    imageDropzone.on("sending", () => {
      this.containerTarget.classList.add("state-upload")
    })

    imageDropzone.on("complete", () => {
      this.containerTarget.classList.remove("state-upload")
      window.location.reload()
    })
  }
}
