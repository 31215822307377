import ApplicationController from "./application_controller"
import flatpickr from "flatpickr"
import moment from "moment"

export default class extends ApplicationController {
  static targets = [
    "dateElement" // optionally set the date element, defaults to this.element
  ]

  static values = {
    smartDateParsing: String
  }

  initialize() {
    this.dateElement = this.hasDateElementTarget ? this.dateElementTarget : this.element
    this.initializeDatePicker()
  }

  initializeDatePicker() {
    // If we are in test environment, back off as flatpickr messes with focus/blur events and effectively makes our
    // date inputs empty in JS tests
    if (document.querySelector(".js-env-test")) {
      return
    }

    if (!this.dateElement.dataset.enableMobile && document.querySelector(".device-mobile")) {
      return
    }

    let params = {
      allowInput: true,
      altInput: false,     // Keep same input format all the time
      dateFormat: "d.m.Y", // Our standard date format
      allowInput: true,    // Avoid readonly attribute being set on input
      minuteIncrement: 1
      // static: true,
    }

    // If data-date-picker-smart-date-parsing-value is present, we should
    // enable smart parsing of dates
    if(this.smartDateParsingValue == "true") {
      params.parseDate = this.smartDateParser
    }

    // set up params for datepicker date-attribute
    // e.g: data: { date_picker: { inline: true } }
    if (this.dateElement.dataset.datePicker) {
      params = {...params, ...JSON.parse(this.dateElement.dataset.datePicker)}
    }

    // Initialize flatpickr for this element
    // Use formatting to match with our general date formatting
    flatpickr(this.dateElement, params)
  }

  // A callback function we set up some places, where we want users to 
  // be able to input very simple numbers and parse them with a bit of control
  smartDateParser(dateStr, format) {
    // Always start with a trimmed string
    // and replace comma for dot. Comma is very easily accessible on
    // Norwegian keypads at least, so allowing for that
    dateStr = dateStr.trim().replaceAll(",", ".").replaceAll("-", ".").replaceAll("/", ".")

    // Can not default to the supplied format because moment.js which we
    // use further down is not using same format strings as flatpickr
    var useFormat = "D.M.Y" // Not same as give format parameter to this function

    // Just one single date provided without month. Assume ahead of time
    if(dateStr.match(/^\d{1,2}$/)) {
      // Matches string like 07 or 7 or 3.

      // Find day of month and see if dateStr is beyond or behind
      // then set current month or next month in the dateStr and use that 
      // to make sure we always set ahead of time
      var todayDate = moment().date()
      var dateStrDate = moment(dateStr, "D", true).date()

      // if date number given is smaller than today's date, assume
      // next month
      if(dateStrDate < todayDate) {
        dateStr = dateStr + "." + moment().add(1, "months").format("M.Y")
      }
      else {
        dateStr = dateStr + "." + moment().format("M.Y")
      }
    }
    else if(dateStr.match(/^\d{1,2}.\d{1,2}$/)) {
      // We have a string on the format "2.7" or "2,7" assuming day 
      // and month numbers, so 2.7 means July 2nd
      // We support comma because of number pads on keyboards 
      // that tend to have comma easily available

      // For this we always want to look ahead of time. Up to one year.
      var dateStrMoment = moment(`${dateStr}.${moment().year()}` , "D.M.Y", true)
      if (dateStrMoment.isBefore(moment())) {
        dateStr = `${dateStr}.${moment().add(1, "years").format("Y")}`
      }
      else {
        dateStr = `${dateStr}.${moment().year()}`
      }
    }
    else if(dateStr.match(/^\d{1,2}.\d{1,2}.\d{2}$/)) {
      // In the case that the user punched a 2 digit year, assume this
      // is short for somewhere in 2000. Passing "YY" forces moment to
      // assume only two digits for the year and effectively turning
      // 24 into 2024
      useFormat = "D.M.YY"
    }

    return moment(dateStr, useFormat, true).toDate();
  }

  // set up "params" and action to create shortcuts
  //   example: To jump to 1 day from now
  //     click->date-picker#jumpTo:stop:prevent", "date-picker-duration-param": 1, "date-picker-unit-param": "day"}, type: "button"} 1 day"
  //
  // Note:
  //   * Get FP instance: https://flatpickr.js.org/instance-methods-properties-elements/
  //   * Using moment().add(Number, String): https://momentjs.com/docs/#/manipulating/add/
  //
  jumpTo(event) {
    const dateTime = moment().add(event.params.duration, event.params.unit)
    this.dateElement._flatpickr.setDate(dateTime.format('D.M.YYYY hh:mm'))
  }
}
