import Rails from "@rails/ujs"
import { controllerExpose } from "../utils/stimulus_utils"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "form",
    "subjectField",
    "squireEditor",
    "contentEditableDiv",
    "contentHiddenField",
    "sendButton"
  ]

  static values = {
    formUrl: String,
    formValidateUrl: String,
    contentCursorPosition: Number
  }

  connect() {
    controllerExpose(this)

    this.initEditableDivDrop()
    this.sendableCheck()

    this.throttleValidateForm = window.utils.throttle(
      this.sendableCheck.bind(this),
      100
    )

    this.sendableCheck = this.sendableCheck.bind(this)

    setTimeout(() => {
      this.squireEditorTarget["stimulus-squire-editor"].editor.addEventListener(
        "input",
        this.throttleValidateForm
      )
    }, 10)
  }

  disconnect() {
    this.squireEditorTarget["stimulus-squire-editor"].editor.removeEventListener("input", this.throttleValidateForm)
  }

  initEditableDivDrop() {
    this.contentEditableDivTarget.addEventListener("dragover", (e) => {
      e.preventDefault()
    })

    this.contentEditableDivTarget.addEventListener("drop", (e) => {
      if (e.dataTransfer.files.length === 0) {
        return
      }

      const attachmentDropzone = $(".js-attachment-uploader")[0]["stimulus-attachment-uploads"].attachmentDropzone

      Array.from(e.dataTransfer.files).forEach((file) => {
        attachmentDropzone.addFile(file)
      })
    })
  }

  save() {
    return new Promise((resolve, reject) =>
      Rails.ajax({
        url: this.formUrlValue,
        type: "PATCH",
        data: new FormData(this.formTarget),
        success: (data) => {
          resolve(data)
        },
        error: (error) => {
          reject(error)
        }
      })
    )
  }

  sendableCheck() {
    if (this.squireEditorTarget["stimulus-squire-editor"]) {
      this.copySquireContentToHiddenField()
    }

    Rails.ajax({
      url: this.formValidateUrlValue,
      type: "POST",
      data: new FormData(this.formTarget),
      success: (data) => {
        const { sendable } = data
        if (sendable) {
          this.sendButtonTarget.classList.remove("disabled")
          this.sendButtonTarget.disabled = ""
        } else {
          this.sendButtonTarget.classList.add("disabled")
          this.sendButtonTarget.disabled = "disabled"
        }
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.copySquireContentToHiddenField()
    this.formTarget.submit()
  }

  async removeShip(event) {
    const { url } = event.currentTarget.dataset
    await this.save()

    const { subject } = await new Promise((resolve, reject) =>
      Rails.ajax({
        url: url,
        type: "DELETE",
        success: (data) => {
          resolve(data)
        },
        error: (error) => {
          reject(error)
        }
      })
    )

    this.subjectFieldTarget.value = subject
    $(".js-circular-builder-selected-ships").trigger("reload")
  }

  copySquireContentToHiddenField() {
    const squireEditor = this.squireEditorTarget["stimulus-squire-editor"].editor
    this.contentHiddenFieldTarget.value = squireEditor.getHTML()
  }

  insertAdditionalContent(additionalContent) {
    const squireEditor = this.squireEditorTarget["stimulus-squire-editor"].editor
    squireEditor.insertHTML(additionalContent)

    // Immediately save the text editor's content after the additional contents are added.
    // This action's purpose is to attach the inserted inline attachments to the ActionText content,
    // preventing the inline attachments from being purged when the inserted contents' original record is deleted.
    this.copySquireContentToHiddenField()
    this.save()
  }
}
