import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { url: String }

  connect() {
    if (!this.urlValue) {
      throw "Could not find attached url."
    }
    window.cardController.openCard()
    window.cardController.cardOpenUrl(this.urlValue)
  }
}
