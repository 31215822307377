import Rails from "@rails/ujs"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    formUrl: String
  }

  createDraft() {
    const form = document.querySelector('.js-email-composer-form');
    const formData = new FormData(form);
    const body = formData.get("email_message[outgoing_rich_text_content]")
    const subject = formData.get("email_message[subject]")

    Rails.ajax({
      url: this.formUrlValue,
      type: "POST",
      data: formData,
      success: () => {
        window.cardController.closeCard()
      },
      error: (err) => {
        alert("Failed to save draft, please try again!");
      }
    })
  }
}