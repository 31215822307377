import ApplicationController from "./application_controller"

// The purpose of this controller is to hide the contained form fields,
// called 'hiddenInputs' _if_ they are all empty
export default class extends ApplicationController {

  static targets = ["hiddenInputs", "expandButton"]

  connect() {

    // If we no inputs within hiddenInputs have values, we shuld
    // hide them. If they have values, back off
    if(!this.hasInputsWithValues()) {
      this.hiddenInputsTarget.classList.add("hidden")
    }

  }

  expandInputs(event) {
    this.hiddenInputsTarget.classList.remove("hidden")
    this.expandButtonTarget.classList.add("hidden")
  }

  hasInputsWithValues() {
    return this.hasTextInputsWithValues() ||
           this.hasSelectInputsWithValues() ||
           this.hasCheckboxesWithValues() ||
           this.hasRadioButtonsWithValues()
  }

  hasTextInputsWithValues() {
    const inputValues = Array
      .from(this.hiddenInputsTarget.querySelectorAll("input[type=text], input[type=tel], input[type=date], textarea"))
      .map(item => item.value)
      .filter(value => value !== "")

    return inputValues.length
  }

  hasCheckboxesWithValues() {
    const checkedCheckboxes = Array.from(this.hiddenInputsTarget.querySelectorAll("input[type=checkbox][checked]"))
    return checkedCheckboxes.length > 0
  }

  hasRadioButtonsWithValues() {
    const checkedRadioButtons = Array.from(this.hiddenInputsTarget.querySelectorAll("input[type=radio][checked]"))
    return checkedRadioButtons.length > 0
  }

  hasSelectInputsWithValues() {
    // Find all options
    // - that are selected, with the 'selected' HTML attribute
    // - that are not with class default (which is an indication
    //   that there is nothing interesting to show, because they are shown by default)
    const selectedNonDefaultOptions = Array
      .from(this.hiddenInputsTarget.querySelectorAll("select"))
      .map((element) => {
        return Array.from(element.options)
          // check for actual attribute being set from server, meaning it is intentionally selected, not just the first option in a dropdown
          .filter(option => option.hasAttribute("selected"))
          .filter(option => !option.classList.contains("default"))
      })
      .filter(selectedNonDefaultOptions => selectedNonDefaultOptions.length > 0)

    // Are there any selectes options?
    return selectedNonDefaultOptions.length > 0
  }

}
