import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  expandPurchaseEnquiry(e) {
    const purchaseEnquiry = e.currentTarget
    const clickedElement = e.target

    // If the clicked element is exempted or is within an exempted element, back off
    if (clickedElement.classList.contains("js-exempt-expand-purchase-enquiry") || clickedElement.closest(".js-exempt-expand-purchase-enquiry")) {
      return
    }

    // Finally, open the purchase enquiry :)
    if (!purchaseEnquiry.classList.contains("expanded")) {
      purchaseEnquiry.classList.add("expanded")
    }
  }

  collapsePurchaseEnquiry(e) {
    // Make sure the click event does not end up bubbling up to the .js-expand-purchase-enquiry listener above..
    e.stopPropagation()
    this.element.classList.remove("expanded")
  }
}
