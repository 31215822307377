class CardController

  constructor: ->

    # Listen to clicks for opening card. See doc for openCardClicked function
    $(document).on("click", "[data-action=open-card]", this.openCardClicked)
    # Listen to clicks for closing card
    $(document).on("click", "[data-action=close-card]", this.closeCardClicked)

    # Listen to clicks on links in card and see if we should
    # just follow them inside the card
    $(document).on("click", "[data-card-link]", this.cardLinkClicked)

    # Hijacks form submits and perform the form action with Ajax instead.
    # Replace the card contents with the Ajax response HTML
    $(document).on("submit", "form[data-card-form]", this.cardFormSubmitted)

    # Clicks outside the card should cause the card to be closed
    $(document).on("click", ".js-card-overlay[data-clickable=true]", this.cardOverlayClicked)

    # Listen for navigating the browser history and open/close the card
    # accordingly
    $(window).on("popstate", this.onPopState)

  # Listen to clicks on open window links and fetch their URL
  # with Ajax instead. Looks for two attributes:
  #
  #   data-url="/some/path"
  #   href="/some/path" attribute
  #
  # If the first data attribute is found, that is used, otherwise
  # looking for href
  openCardClicked: (e) =>

    e.preventDefault()
    # Prevent bubbling up to a surrounding open window listener for instance
    e.stopPropagation()

    $clickedElement = $(e.currentTarget)

    cardClass = $clickedElement.data("card-class")
    # Should it be possible to close the card by clicking the overlay?
    # Default is that it should, but
    # if data-card-overlay-clickable="false" it should not be possible
    overlayClickableAttr = $clickedElement.attr("data-card-overlay-clickable")
    overlayClickable = overlayClickableAttr != "false"

    # Should it show back arrow, to close card?
    backArrowAttr = $clickedElement.attr("data-card-back-arrow")
    backArrow = backArrowAttr == "true"

    # Open the card right away, since it feels more responsive
    this.openCard(cardClass, overlayClickable, backArrow)

    linkToOpen = $clickedElement.data("url") || $clickedElement.attr("href")
    unless linkToOpen
      throw "Could not find data-url or href on clicked element #{$clickedElement}"

    # Add _card with card AJAX URL as part of the browser URL and add to history
    url = new URL(window.location.href)
    # Just in case the url contained the card we remove it before appending it again
    url.searchParams.delete("_card")
    url.searchParams.append("_card", linkToOpen)
    # And update the URL and history of the browser
    window.history.pushState({}, null, url.toString())

    this.cardOpenUrl(linkToOpen)

  # Set the content of the current card and emits lifecycle events for the card
  # Defaults to sending events for both teardown and open, but
  cardContent: (html, wrapperCss = "") ->
    cardLevel = this.getCurrentLevel()

    # Set the contents of the card
    $(".js-card-overlay[data-card-level='#{cardLevel}'] .js-card-wrapper").html(html)
    $(".js-card-overlay[data-card-level='#{cardLevel}'] .js-card-wrapper").addClass(wrapperCss)


  # Open the card
  openCard: (cssClass = "", cardOverlayClickable = true, backArrow = false) =>
    openLevels = this.getCurrentLevel()
    cardLevel = openLevels + 1

    $(".js-card-overlay[data-card-level=#{cardLevel}]")
      .removeClass("hidden fade-in-up-half")
      .attr("data-clickable", cardOverlayClickable)

    if backArrow
      $(".js-back-arrow-close-icon").removeClass("d-none")
      $(".js-x-close-icon").addClass("d-none")

    $(".js-card-overlay[data-card-level=#{cardLevel}] .js-card")
      .removeClass("hidden")
      .addClass(cssClass)
      .addClass("fade-in-up-half")
    # Open the card with no events as there is no contents to attach
    # listeners to just yet
    this.cardContent("")
    # Add helper class to body so we can hide the document level scrollbar
    this.setCardOpenClassOnBody()

  getCurrentLevel: =>
    $(".js-card-overlay").not(".hidden").length

  closeCardClicked: (e) =>
    e.preventDefault()
    this.closeCard()

  closeCard: () =>
    cardLevel = this.getCurrentLevel()

    # Clear contents for next time it is opened
    this.cardContent("")

    # Remove _card part of URL if present and update browser history
    url = new URL(window.location.href)
    # TODO: Known bug: should ensure we put the underlying cards URL in the _card part of the
    # URL in case we closed more than level 1

    # Just in case the url contained the card we remove it before appending it again
    url.searchParams.delete("_card")
    # And update the URL and history of the browser
    window.history.pushState({}, null, url.toString())

    $(".js-card-overlay[data-card-level=#{cardLevel}]")
      .addClass("hidden")
      .removeAttr("data-clickable")
    # Remove any other classes from .card and hide it again!
    $(".js-card-overlay[data-card-level=#{cardLevel}] .js-card").attr("class", "js-card card").addClass("hidden")

    this.setCardOpenClassOnBody()


  cardOverlayClicked: (e) =>
    # Click events might have bubbled from the card itself, so important to
    # verify the overlay was actually clicked
    if $(e.target).is(".js-card-overlay")
      this.closeCard()

  cardOpenUrl: (url) =>
    $.get(url, (html) =>
      this.cardContent(html)
      # Track with JS if tracker is present
      window.tracker?.track(url)
    )

  cardLinkClicked: (e) =>
    e.preventDefault()

    $clickedElement = $(e.currentTarget)
    linkToOpen = $clickedElement.data("url") || $clickedElement.attr("href")

    unless linkToOpen
      throw "Could not find data-url or href on clicked element #{$clickedElement}"

    this.cardOpenUrl(linkToOpen)

  cardFormSubmitted: (e) =>
    e.preventDefault()
    $form = $(e.currentTarget)

    $.ajax({
      type: $form.attr("method") || "get",
      url: $form.attr("action") || throw "Could not find action on form" ,
      data: $form.serialize(),
      success: (data) =>
        this.cardContent(data)
    })

  # When user navigates back or forward in browsing history.
  # This typically happens when clicking back button on browser, but will also
  # bee triggered when navigating history programmatically ( like history.back() )
  onPopState: (e) =>

    # Classic back button use case: If the card is open, we close it!
    if(this.isCardOpen())
      this.closeCard()
      return

    # If user is navigating forward, we need to check if we should open
    # the card again. Look for the _card param
    url = new URL(window.location.href)
    linkToOpen = url.searchParams.get("_card")
    if linkToOpen

      linkUrl = new URL(linkToOpen, window.location.origin)
      if linkUrl.origin != url.origin
        throw new Error("not a legal origin")

      # Finally, re-open card from the url
      this.openCard()
      this.cardOpenUrl(linkUrl.toString())

  isCardOpen: =>
    this.getCurrentLevel() > 0

  setCardOpenClassOnBody: =>
    if this.isCardOpen()
      $("body").addClass("card-open")
    else
      $("body").removeClass("card-open")


  # Emit events on card on different lifecycle actions in order to set up
  # any needed event listeners
  triggerLoadEvent: =>
    cardLevel = this.getCurrentLevel()
    $(".js-card-overlay[data-card-level=#{cardLevel}] .js-card").trigger("card:load")

  triggerBeforeTeardownEvent: =>
    cardLevel = this.getCurrentLevel()
    $(".js-card-overlay[data-card-level=#{cardLevel}] .js-card").trigger("card:before-teardown")

window.cardController = new CardController()
