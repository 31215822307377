import Dropzone from "dropzone"
import {observe} from "selector-observer"

# Disable Dropzone auto discovery to avoid supprise behaviours.
Dropzone.autoDiscover = false

# This controller handles documents upload inside a card.
# To enable document upload for a card add this line to card tabs partial
# so that it will be available in all card tabs.
# For example
# = render "documents/uploadable", attachable: @ship_info

class CardUploadsController

  constructor: ->
    observe(".js-documents-url", add: this.cardLoaded, remove: this.beforeTeardown)

  cardLoaded: (e) =>
    # the reason to destroy Dropzone instances here is
    # because .js-card is never removed after each upload and the instances
    # are still hanging there while the events need to be reinitialized.
    if Dropzone.instances.length > 0
      # Only destroy Dropzone instances inside .js-card
      for instance in Dropzone.instances when instance.element.classList.contains("js-card")
        instance.destroy()

    # TODO: remove Dragster
    $card = $(".js-card")
    this.dragsterInstance = new Dragster($card[0])

    dropzoneOptions = {
      url: $(".js-documents-url").data("url"),
      maxFilesize: 100,
      previewsContainer: ".js-documents-preview-container",
      createImageThumbnails: false,
      parallelUploads: 5,
    }

    # There is only a clickable element if it is present upon
    # initialization of the card
    if $(".js-upload-trigger").length > 0
      dropzoneOptions.clickable = ".js-upload-trigger"
    else
      # If we don't do this, mobile devices will try to trigger uploads
      # by clicking anywhere inside a card
      dropzoneOptions.clickable = false

    # Storing this directly on the controller, as the card is a singleton
    # concept. I.E. it will only ever be one active card at once.
    this.cardDropzone = new Dropzone(".js-card", dropzoneOptions)

    # Set up dragster events that handle event bubbling to avoid
    # glitching when dragging on many browser
    document.addEventListener("dragster:enter", (e) ->
      # Check if a file is dragged (and not another DOM element) before
      # adding dragged-over state
      event = e.detail?.parentEvent
      return unless event
      if event.dataTransfer?.types
        if "Files" in event.dataTransfer.types
          $(e.target).addClass("dragged-over")
    , false)

    document.addEventListener("dragster:leave", (e) ->
      $(e.target).removeClass("dragged-over")
    , false)

    this.cardDropzone.on "sending", (file, xhr, formData) =>
      # dropZone seems to call this twice for subsequent uploads and that f"#%s
      # up the csrf-header setting as it is appended twice, and Rails wont
      # accept the incoming request. Hard to find a good way to avoid calling
      # the CSRFProtection more than once so disabling csrf protection on this
      # action for now
      #
      # $.rails.CSRFProtection(xhr)
      this._uploadProgressStart()

    this.cardDropzone.on "totaluploadprogress", (progress) =>

      this._setUploadProgress(progress)

    this.cardDropzone.on "complete", (file) =>
      return unless this.cardDropzone.getUploadingFiles().length == 0
      return unless this.cardDropzone.getQueuedFiles().length == 0
      # For some reason the complete event is triggered multiple times with
      # the same file passed. To avoid performing a complete-action multiple
      # times, we store the last passed completed file on this singleton
      # instance. That way we know that if that file is passed again, we have a
      # duplicate complete event, and should not act on this event.
      return if this.lastCompleteFile && this.lastCompleteFile == file
      # If we passed the guard statement, store this file for later
      this.lastCompleteFile = file

      # Finally, we are certain that uploads are complete!
      this._uploadProgressComplete()

  beforeTeardown: (e) =>
    # Clean up dragster event handlers
    this.dragsterInstance.removeListeners()
    # Clear the lastCompleteFile
    this.lastCompleteFile = null

  _uploadProgressStart: (e) =>
    $(".js-card").removeClass("dragged-over")
    $(".js-card").addClass("uploading")

  _setUploadProgress: (progress) =>
    $(".js-documents-upload-progress .js-progress-bar").css(width: "#{progress}%")

  _uploadProgressComplete: () =>
    $(".js-documents-tab").click() # Go to the documents tab to see newly uploaded docs
    $(".js-card").removeClass("uploading")

window.cardUploadsController = new CardUploadsController()
