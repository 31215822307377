import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["imoNumber"]
  static values = { getImoNumberUrl: String }

  getImoNumber() {
    fetch(this.getImoNumberUrlValue)
      .then((response) => response.json())
      .then((data) => {
        this.imoNumberTarget.value = data.imo_number
      })
  }
}
