import ApplicationController from "./application_controller"
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = ["multiImoExtractButton", "multiImoTextArea", "multiImoPasteArea", "imoCounter", "dropdown", "dropdownButton"]

  static values = {
    url: String
  }

  extract(e) {
    e.preventDefault()
    e.stopPropagation()

    const url = this.urlValue
    const multi_imo_text = this.multiImoPasteAreaTarget.value
    const formData = new FormData()
    formData.append(
      "multi_imo_text",
      multi_imo_text
    )
    Rails.ajax({
      url: url,
      type: "POST",
      data: formData,
      success: (response) => {
        const imos = response.extracted_imos.join(", ")
        this.multiImoTextAreaTarget.value = imos
        this.multiImoPasteAreaTarget.value = imos
        this.imoCounterTarget.textContent = response.extracted_imos.length
        this.dropdownTarget.style.display = "none"
        this.dropdownButtonTarget.classList.remove("focus")
      }
    })
  }
}
