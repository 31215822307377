import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = {
    userId: String
  }

  static targets = [
    "email"
  ]

  initialize() {
    this.subscription = consumer.subscriptions.create(
      { channel: "EmailVerificationStatusChannel" },
      {
        received: this.updateStatus.bind(this)
      }
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  updateStatus(data) {
    if (this.emailTarget.innerHTML == data.email) {
      if ( data.email_verified != undefined && !data.email_verified ) {
        this.emailTarget.classList.add("text-danger");
        this.emailTarget.title = "Could not verify email address"
      } else if ( data.email_verified != undefined && data.email_verified ) {
        this.emailTarget.classList.remove("text-danger")
        this.emailTarget.title = ""
      }
    }
  }
}
