import ApplicationController from "./application_controller"

/*
  This controller works with two cases of scrolling:
    * scrolling on the window / document level (default)
    * or by specifying a 'scrollContainer' and 'scrollContent' targets

  The result of the pagination is by default appended to the scroll controller
  element or otherwise can be specified with 'append' target.

  Supports multiple scrollables in the same page.

  Usage examples:
    * Basic usage:
      ```
        %table{data: {controller: "endless-scroll",
                      endless_scroll_url_value: append_contacts_path(params_to_hash)}}
      ```

    * With target options:
      ```
        .wrapper{data: {controller: "endless-scroll",
                        endless_scroll_url_value: append_ships_path(segment, params_to_hash),
                        endless_scroll_target: "scrollContainer"}}
          %table{data: {endless_scroll_target: "append scrollContent"}}
      ```
*/
export default class extends ApplicationController {
  static targets = [
    "scrollContainer", // scroll container, the one that will have the scroll bar. Default: window
    "scrollContent",   // the content to scroll. Default: document
    "append"           // target element where we'll append the paginated result. Default: this.element
  ]

  static values = {
    url: String,      // the URL to fetch paginated data
    page: { type: Number, default: 1 },
    isRunningAjax: { type: Boolean, default: false },
    finished: { type: Boolean, default: false }
  }

  connect() {
    // Default scroll target is window, if a selector was not defined on the endless scroll element
    this.scrollContainer = this.hasScrollContainerTarget ? this.scrollContainerTarget : window

    // Store the throttled Scroll callback
    this.throttledScrollFunction = utils.throttle(this.targetScrolled())

    // Set up event listener for scrolling
    this.scrollContainer.addEventListener('scroll', this.throttledScrollFunction)

    // TODO: look into if we need this or not:
    // Trigger targetScrolled once, just to load data at once, in case the
    // screen is big and there is not enough data
    // this.targetScrolled()
  }

  disconnect() {
    this.scrollContainer.removeEventListener('scroll', this.throttledScrollFunction)
  }

  targetScrolled() {
    return () => {
      // Default scroll content is document, if a selector was not defined on the endless scroll element
      const content = this.hasScrollContentTarget ? this.scrollContentTarget : document.body
      const distanceToBottom = content.scrollHeight - (this.scrollContainerScrollTop() + this.scrollContainerHeight())

      if (distanceToBottom < 800) this.fireAjax()
    }
  }

  // content already scrolled
  scrollContainerScrollTop() {
    return this.hasScrollContainerTarget ? this.scrollContainerTarget.scrollTop : window.pageYOffset
  }

  // viewable height of the scroll container
  scrollContainerHeight() {
    return this.hasScrollContainerTarget ? this.scrollContainerTarget.offsetHeight : window.innerHeight
  }

  async fireAjax() {
    // Safeguard:
    //   If finished loading all content, back off
    //   If ajax is running, back off
    if (this.finishedValue || this.isRunningAjaxValue) return

    this.isRunningAjaxValue = true

    let url = this.urlValue;
    url += url.includes("?") ? "&" : "?"
    url += "page=" + (this.pageValue + 1)
    const response = await fetch(url)
    const data = await response.text()

    // Check if the response is JSON
    // Response might be JSON on the form:
    //   {"alert": "Message"}
    //   or, if it's just a string, assume HTML
    let jsonData
    try {
      jsonData = JSON.parse(data)
    } catch (error) {
      // Response is not JSON
      jsonData = null
    }

    if (jsonData && typeof jsonData === "object") {
      // Make sure to mark the DOM to say we should stop firing ajax requests
      this.finishedValue = true

      if (jsonData.alert) alert(jsonData.alert)
    } else {
      // No more content from server? If so, back off
      // Make sure to mark the DOM to say we should stop firing ajax requests
      if (data.trim().length === 0) this.finishedValue = true

      const appendTarget = this.hasAppendTarget ? this.appendTarget : this.element
      appendTarget.insertAdjacentHTML("beforeend", data)
    }

    this.pageValue++ // When done, increase page number
    this.isRunningAjaxValue = false // And turn off safeguard
  }
}
