import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  openExpandableItem() {
    const item = this.element
    item.classList.remove("closed")
    item.classList.add("expanded")
  }

  closeExpandableItem(e) {
    e.stopPropagation()

    const item = this.element
    item.classList.remove("expanded")
    item.classList.add("closed")
  }
}
