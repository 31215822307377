$(document).ready ->
  $element = $(".js-beforeunload")

  if $element.length
    # Check if the input elements have been updated
    $(document).on "keydown change", $element.data("inputs-selector"), (event) ->
      $(event.target).addClass("js-changes-made")

    $(window).on 'beforeunload', (e) ->
      if $(".js-changes-made").length
        # Warn user
        # Looks like only IE shows this message, Chrome and FF shows it's own
        # generic message
        return "Data you have entered may not be saved."

      # Don't warn
      return undefined
