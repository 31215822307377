# Set up simple widgets to work across the entire application
class PageController

  constructor: ->

    $(document).on "reload", "[data-reload-url]", this.reloadTarget
    $(document).on "click",  "[data-highlight-on-click]", this.highlightTextInInput
    $(document).on "click",  "[data-submit-to]",  this.submitFormToOtherTarget
    $(document).on "change", "[data-submit-selected-url]", this.submitToUrlOnInputChange
    $(document).on "change", ":input[data-submit-on-change]", this.submitFormOnInputChange
    $(document).on "click",  ".js-stop-propagation", this.stopPropagation

  # Utility to stop propagation of an event
  stopPropagation: (e) =>
    e.stopPropagation()

  # Utility that allows any partial to be reloaded by adding a data attribute
  # data-reload-url="some_url" and by triggering a "reload" event on the element
  #
  # Assuming a Rails controller to back up the partial is in place
  reloadTarget: (e) =>
    # when there are nested reloadable components, prevent reloading outer
    # component when we trigger reload on the inner component.
    #   Ex: On PurchaseEnquiry list page:
    #     * outer: reloadable PE list
    #     * inner: reloadable log-items on each PE card
    this.stopPropagation(e)

    $target = $(e.currentTarget)

    $.get($target.data("reload-url"), (html) ->
      $target.replaceWith(html)
    )

  highlightTextInInput: (e) =>
    $input = $(e.currentTarget)
    $input.focus()
    $input.select()


  # In button groups, links with data-submit-to is very practical
  # for submitting forms to different urls
  submitFormToOtherTarget: (e) =>
    e.preventDefault()
    $clickedElement = $(e.currentTarget)
    $clickedElement.parents("form")
      .attr("action", $clickedElement.data("submit-to"))
      .submit() # Note, this will only work for non-remote forms!! See https://github.com/rails/jquery-ujs/wiki/How-to-trigger-a-form-submit-from-code


  # For a select box with data-submit-to-url-on-change present
  # listen for a change, and look for a url on the selected option
  # with data attribute data-submit-to. Use this url as the form
  # action of the parent form and submit the form with the new action
  #
  # Expected markup is something in the lines of:
  # <form action="/my/url" method="get">
  #   <select data-submit-selected-url>
  #     <option value="foo" data-submit-to="/foo/url">Foo</option>
  #     <option value="bar" data-submit-to="/bar/url">Bar</option>
  #   </select>
  # </form>
  #
  # And will submit the sorrounding form to /foo/url or /bar/url on a change event
  # on the select
  #
  submitToUrlOnInputChange: (e) =>
    e.preventDefault()

    submitUrl = $(e.currentTarget).find(":selected").data("submit-to")
    # Set the parent"s form to
    $(e.currentTarget).parents("form")
      .attr("action", submitUrl)
      .submit() # Note, this will only work for non-remote forms!! See https://github.com/rails/jquery-ujs/wiki/How-to-trigger-a-form-submit-from-code

  # Any form input with data-submit-on-change will automatically
  # submit the parent form on a change event
  submitFormOnInputChange: (e) =>
    # Note, this will only work for non-remote forms!! See https://github.com/rails/jquery-ujs/wiki/How-to-trigger-a-form-submit-from-code
    $(e.currentTarget).parents("form").submit()



window.pageController = new PageController()
