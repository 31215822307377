import {observe} from "selector-observer"

class ColorPickerController

  constructor: ->

    observe(".js-colorpicker", add: this.intiColorpicker, remove: this.destroyColorpicker)


  intiColorpicker: (element) =>
    $(element).colorpicker({
      format: "hex"
    })

  destroyColorpicker: (element) =>
    $(element).colorpicker("destroy")

window.colorpickerController = new ColorPickerController()
