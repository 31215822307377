export const validEmailPatternRegex = (validEmailPatternObject) => {
  const { source, options } = validEmailPatternObject
  return new RegExp(source, options)
}

export const isMatchEmailPatternRegex = (text, validEmailPatternObject) => {
  return validEmailPatternRegex(validEmailPatternObject).test(text)
}

export const getEmailFromSelect2SelectedOption = (selectedOption) => {
  return selectedOption.text
}

export const isSelect2SelectedOption = (selectedOptions, term) => {
  return selectedOptions.find((selectedOption) => {
    const email = getEmailFromSelect2SelectedOption(selectedOption)
    return email.toLowerCase() === term.toLowerCase()
  })
}
