import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  openLink(event) {
    window.location.href = event.currentTarget.dataset.href
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
