class LoadMoreController

  constructor: ->
    $(document).on "click", "[data-load-more-url]", this.loadMore

  loadMore: (e) =>
    $loadMoreUrlElement = $(e.currentTarget)

    # Safeguard: If finished loading all content, back off
    return if $loadMoreUrlElement.data("finished")

    # Safeguard: If ajax is running, back off
    return if $loadMoreUrlElement.data("isRunningAjax")
    $loadMoreUrlElement.data("isRunningAjax", true)

    # Now extract the page we are on
    page = $loadMoreUrlElement.data("page") || 1
    nextPageNumber = page + 1

    $.get($loadMoreUrlElement.data("loadMoreUrl"), {page: nextPageNumber}).done( (data) =>
      # No more content from server? If so, back off
      if $.trim(data).length == 0
        $loadMoreUrlElement.data("finished", true)
        $loadMoreUrlElement.replaceWith("")

      else
        $loadMoreUrlElement.before(data)
        # When done, increase page number
        $loadMoreUrlElement.data("page", nextPageNumber)
        # And turn off safeguard
        $loadMoreUrlElement.data("isRunningAjax", false)
    )

window.loadMoreController = new LoadMoreController()
