# This one will be only working in one page for now
class ResizableGridFieldsController
  constructor: ->
    @resizableTable = ".js-table-resizable"
    @resizableEl = "#{@resizableTable} .th"
    $(document).on "mousedown", @resizableEl, @startResize

  startResize: (e) =>
    $(document).on "mousemove", @resize
    $(document).on "mouseup", @endResize
    $target = $(e.currentTarget)
    $resizableTable = $target.parents(@resizableTable)

    $resizableTable.data("currentResizeEl", ".#{$target.data("name")}")
    $resizableTable.data("startWidth", $resizableTable.width())
    $target.data("startX", e.pageX)
    $target.data("startWidth", $target.width())

  resize: (e) =>
    $resizableTable = $(@resizableTable)
    return unless $resizableTable.data("currentResizeEl")

    $resizingEl = $resizableTable.find($resizableTable.data("currentResizeEl"))
    startWidth = $resizingEl.data("startWidth")
    startX = $resizingEl.data("startX")
    resizeValue = e.pageX - startX

    # limit the min width of the $resizingEl to be 20
    if (startWidth + resizeValue) <= 20
      resizeValue = 20 - startWidth

    $resizingEl.width(startWidth + resizeValue)
    $resizableTable.width($resizableTable.data("startWidth") + resizeValue)

  endResize: () =>
    $resizableTable = $(@resizableTable)
    return unless $resizableTable.data("currentResizeEl")

    $(document).off "mousemove", @resize
    $(document).off "mouseup", @endResize
    @saveNewWidth($resizableTable.data("currentResizeEl"))
    $resizableTable.removeData("currentResizeEl")

  saveNewWidth: (el) =>
    $target = $(el)
    data =
      name: $target.data("name")
      width: parseInt($target.css("width"), 10) # take only number from width for now
      mode: $target.data("mode")
    $.post $target.data("resizeUrl"), data, (response) ->

window.resizableGridFieldsController = new ResizableGridFieldsController()
