import Rails from "@rails/ujs"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    url: String
  }

  connect() {
    this.timeoutId = setTimeout(() => {
      Rails.ajax({
        url: this.urlValue,
        type: "POST"
      })
    }, 600)
  }


  disconnect(){
    clearTimeout(this.timeoutId)
  }
}
