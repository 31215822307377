import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["companySelect", "companyNameInput", "contactEmailInput", "companyIdHiddenInput"]

  static values = {
    remoteUrl: String
  }

  companySelectTargetConnected() {
    this.initializeSelect2()
  }

  companySelectTargetDisconnected() {
    this.destroySelect2()
  }

  initializeSelect2() {
    const $companySelect = $(this.companySelectTarget)
    const self = this

    $companySelect.select2({
      dropdownParent: self.element,
      allowClear: true,
      theme: "bootstrap4",
      ajax: {
        url: self.remoteUrlValue,
        dataType: "json",
        delay: 250,
        data: function (params) {
          return {
            name: params.term
          }
        },
        processResults: function (data, params) {
          return {
            results: data
          }
        },
        cache: true
      },
      placeholder: "Search for a company",
      minimumInputLength: 3,
      templateResult: self.formatCompany,
      templateSelection: self.formatCompanySelection
    })

    $companySelect.on("change.select2", function() {
      const selected = $companySelect.select2("data")[0]

      if (selected) {
        self.companyNameInputTarget.value = selected.short_name
        self.companyNameInputTarget.setAttribute("disabled", true)
        self.companyIdHiddenInputTarget.value = selected.id

        if (!self.contactEmailInputTarget.value) {
          self.contactEmailInputTarget.value = selected.email
        }
      } else {
        self.companyNameInputTarget.value = ""
        self.companyNameInputTarget.removeAttribute("disabled")
        self.companyIdHiddenInputTarget.value = ""
      }
    })
  }

  destroySelect2() {
    $(this.companySelectTarget).select2("destroy")
  }

  formatCompany(company) {
    if (company.loading) {
      return company.text
    } else {
      return $(`
                <div class="p-2">
                  ${company.short_name}
                  <br>
                  <small>${company.full_address}</small>
                </div>
              `)
    }
  }

  formatCompanySelection(company) {
    return company.short_name
  }
}
