# Generic JS for updating fields inline.
# Expects markup on the form
#
# .js-editable
#   .js-content= @model.field_name
#   .js-form.hidden
#     %form
#       %input.js-autofocus <- this element will be focused when .js-content is clicked
#       %input.js-editable-cancel <- this will cuse the element to go out of editable modus
#

# Hide other opened .js-editable elements
# If a jQuery object is passed, use that as baseline filter for the editable elements
# Otherwise, all open elements will be hidden.
hideAllOpened = ($element = null) ->
  # Find one or all elements to hide
  $all = $element || $(".js-editable")

  $all.find(".js-content").removeClass("hidden")
  $all.find(".js-form").addClass("hidden")


$(document).on("click", ".js-editable .js-content", (e) ->
  # The clicked element might be a link (on mobile), so preventing
  # regular link behavior
  e.preventDefault()

  $specContainer = $(e.currentTarget).parents(".js-editable")

  hideAllOpened()

  # Then make the clicked one visible
  $specContainer.find(".js-content").addClass("hidden")
  $specContainer.find(".js-form").removeClass("hidden")
  $specContainer.find(".js-form form .js-autofocus").focus().select()
)

$(document).on("click", ".js-editable .js-editable-cancel", (e) ->
  # Ensure form is not submitted
  e.preventDefault()

  $specContainer = $(e.currentTarget).parents(".js-editable")

  # Hide the current form again
  $specContainer.find(".js-content").removeClass("hidden")
  $specContainer.find(".js-form").addClass("hidden")
)

# On mobile expecially, it makes sense to remove input element
# on blur events typically triggered by closing keyboard
$(document).on("blur", ".js-editable .js-form .js-hide-on-blur", (e) ->
  $editable = $(e.currentTarget).parents(".js-editable")

  # Since the user might have jumped to another input, we do not want to
  # hide the newly opened input. Therefore only hiding the blurred element
  setTimeout( ->
    hideAllOpened($editable)
  , 1000)
)
