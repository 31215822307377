import ApplicationController from "./application_controller"

// The purpose of this controller is to emulate a regular remote
// form submit. We could not use form tags however, since this is
// inside another larger form (advanced search). So to avoid the
// problem we just need wrap a form field ("name") with this
// controller and submit it with JS like a form
export default class extends ApplicationController {
  static values = { url: String }
  static targets = [ "name" ]

  submitForm(event) {
    // Stop propagation so we don't submit the parent form
    event.preventDefault()
    event.stopPropagation()

    const formData = new FormData();
    formData.append(this.nameTarget.name, this.nameTarget.value)

    Rails.ajax({
        type: "POST",
        url: this.urlValue,
        data: formData,
        // Just firing the request. The result gets populated
        // from the JS response, so no success callback necessary
        // success: () => {}
      })

  }
}
