import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    modelId: String
  }
  static targets = [ "textArea" ]

  connect(){
    this.submitEventHandler = this.clearDraft.bind(this)
    this.element.addEventListener("submit", this.submitEventHandler);
    this.loadDraft()
  }

  disconnect(){
    this.element.removeEventListener("submit", this.submitEventHandler);
  }

  storeDraft() {
    localStorage.setItem(this.modelIdValue, this.textAreaTarget.value);
  }

  loadDraft(){
    this.textAreaTarget.value = localStorage.getItem(this.modelIdValue);
  }

  clearDraft(){
    localStorage.removeItem(this.modelIdValue);
  }
}
