class AdvancedSearchFormController

  constructor: ->
    # Clear/Reset search form using JS
    $(document).on "click", ".js-clear", this.clearForm
    # On any form change, reload the saved search partial
    $(document).on "change", ".js-search-form-with-shortcut", this.updateShortcutsOnSearchFormChange


  clearForm: (e) =>

    e.preventDefault()
    $form = $(e.currentTarget).parents(".js-advanced-search-form")
    $form.find("select, input.can-clear:not([type=radio], [type=checkbox])").val("")
    $form.find("select option.default").prop("selected", true)
    $form.find("input.can-clear[type=radio]").prop("checked", false)
    $form.find("input.can-clear[type=checkbox]").prop("checked", false)

    # Ensure we trigger a change so shortcuts are reloaded
    $(".js-search-form-with-shortcut").trigger("change")

  updateShortcutsOnSearchFormChange: (e) =>
    # Wait just a little bit to make sure any form updates
    # are done
    setTimeout(=>

      # Back out if we are inside the tag for ship search shortcuts
      # within the form. We don't want that to be overwritten
      if $(e.target).parents(".js-ship-search-shortcuts").length > 0
        return

      $form = $(e.currentTarget)

      $shortcutsEl = $(".js-ship-search-shortcuts")

      # Back out before triggering an ajax request if there is nowhere
      # to get the URL
      return unless $shortcutsEl.length > 0

      # We are good, let's reload
      $.get($shortcutsEl.data("reload-url"), $form.serialize(), (html) ->
        $shortcutsEl.replaceWith(html)
      )
    , 10)

window.advancedSearchFormController = new AdvancedSearchFormController()
