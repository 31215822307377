# For stateless JS sprinkles on single fields
# on desktop and mobile
class AutopadFieldsController

  constructor: ->

    $(document).on("blur", ".js-autopad-with-zeros", this.autopadWithZeros)

    $(document).on("blur", ".js-autopad-year", this.autopadYear)


  #
  # Autopad numbers with zeros for applicable fields
  #
  autopadWithZeros: (e) =>

    $input = $(e.currentTarget)
    numberValue = $input.val()
    numberOfDigits = "#{numberValue}".length

    # For numbers larger than 499 we should never pad with zeros, as
    # no ships in the world are larger than low 400s in deadweight.
    # Note that we also use this for CBM and grain fields, and
    # there is one ship in the world with CBM more than 500,000 but
    # there is not much value in supporting auto-padding for that case
    # so we believe autopadding numbers between 1 - 500 is a good tradeoff
    if numberOfDigits <= 3 && numberValue > 0 && numberValue < 500
      $input.val(numberValue * 1000)

  # Autopad a field with full 4 digit year if only last to digits
  # were supplied
  autopadYear: (e) =>
    $input = $(e.currentTarget)

    numberValue = $input.val()
    numberOfDigits = "#{numberValue}".length

    # For numbers with anything else than 1 or 2 digits, to
    # not touch
    if numberOfDigits == 0 || numberOfDigits >= 3
      return

    # For numbers with 1 and 2 digits, prepend the year
    # with 19 or 20 depending on the year indicated
    twoDigitYear = parseInt(numberValue)
    return if isNaN(twoDigitYear)
    if twoDigitYear >= 50
      year = 1900 + twoDigitYear
    else
      year = 2000 + twoDigitYear

    $input.val(year)


window.autopadFieldsController = new AutopadFieldsController()
