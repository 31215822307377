# Load contents into any element with a data attribute data-lazy-load
#
#   <div data-lazy-load="/some/path"></div>
#
# The above will trigger an Ajax request to /some/path and inject the result
# as HTML into the div.
#
class LazyLoadController

  constructor: ->

    $(document).ready(this.documentLoaded)


  documentLoaded: () =>
    # For every lazy load element that is on the page,
    # load HTML into them with ajax
    $("[data-lazy-load]").each (i, elem) ->

      $elem = $(elem)
      url = $elem.data("lazyLoad")
      $.get(url, (html) ->
        $elem.html(html)
      )


window.lazyLoadController = new LazyLoadController()
