# A wrapper over the analytics solution we are using
# Exposed as window.tracker
class Tracker

  # Track a url
  track: (url) ->
    return unless window.gtag

    gtag('event', 'page_view', {
      page_location: url
    });

  # Track an event
  event: (category, action, label) ->
    return unless window.gtag

    gtag('event', 'page_view', {
      eventCategory: category, #'Videos',
      eventAction: action, # 'play',
      eventLabel: label # 'Fall Campaign'
    });


# reveal a tracker object that can be used to track anywhere with tracker.track()
window.tracker = new Tracker

# Generic click tracking using data attributes
$(document).on("click", "[data-track-event=click]", (e) ->
  category = $(e.currentTarget).attr("data-track-category")
  action = "click"
  label = $(e.currentTarget).attr("data-track-label")
  tracker.event(category, action, label)
)
