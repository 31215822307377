import Dropzone from "dropzone"
import { csrfTokenHeader } from "../utils/ajax_request"
import { controllerExpose } from "../utils/stimulus_utils"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["previews", "click", "attachments"]
  static values = { paramName: String, url: String }

  connect() {
    controllerExpose(this)

    const dropzoneOptions = {
      clickable: this.clickTarget, // Use an external button instead of clicking on the whole dropzone
      createImageThumbnails: false,
      headers: csrfTokenHeader(),
      paramName: this.paramNameValue,
      previewsContainer: this.previewsTarget,
      url: this.urlValue
    }

    this.attachmentDropzone = new Dropzone(this.element, dropzoneOptions)

    this.attachmentDropzone.on("sending", () => {
      this.element.classList.add("state-upload")
    })

    this.attachmentDropzone.on("complete", () => {
      this.element.classList.remove("state-upload")
      $(this.attachmentsTarget).trigger("reload")
    })
  }

  disconnect() {
    this.attachmentDropzone.destroy()
  }
}
