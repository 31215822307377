class DraggableController
  constructor: ->
    @draggableEl = ".js-draggable"
    $(document).on "dragstart", @draggableEl, @handleDragStart
    $(document).on "dragover", @draggableEl, @handleDragOver
    $(document).on "dragleave", @draggableEl, @handleDragLeave
    $(document).on "drop", @draggableEl, @handleDrop
    $(document).on "dragend", @draggableEl, @handleDragEnd

    # drag using handle
    @draggableHandle = ".js-draggable-handle"
    @draggableWrapper = ".js-draggable-wrapper"
    $(document).on "mouseover", @draggableHandle, @handleOnMouseOverDragHandle
    $(document).on "mouseout", @draggableHandle, @handleOnMouseOutDragHandle

    # Firefox will sometimes get a drop event on the body/outside
    # everything defined here.
    # If we do not handle that, Firefox will typically try to open whatever is in
    # dataTransfer as a URL..
    $(document).on "drop", "body", @handleDropOnBody

  handleDragStart: (e) =>
    @dragSrcEl = e.currentTarget
    $target = $(e.currentTarget)

    e.originalEvent.dataTransfer.effectAllowed = "move"
    # Not storing text/plain to dataTransfer, as Firefox will sometimes
    # try to open the dropped element as a link (using the value of the text/plain
    # data as the URL, even if it's not a real URL)
    e.originalEvent.dataTransfer.setData("text/html", e.currentTarget.outerHTML)

    true

  handleDragEnter: (e) =>
    e.stopPropagation()
    e.preventDefault()

  handleDragOver: (e) =>
    e.stopPropagation()
    e.preventDefault()

    if $(@dragSrcEl).index() > $(e.currentTarget).index()
      e.currentTarget.parentNode.insertBefore(@dragSrcEl, e.currentTarget)
    else
      e.currentTarget.parentNode.insertBefore(@dragSrcEl, e.currentTarget.nextSibling)

    $(e.currentTarget).addClass("over")
    e.originalEvent.dataTransfer.dropEffect = "move"
    true

  handleDragLeave: (e) =>
    $target = $(e.currentTarget)
    $target.removeClass("over")
    true

  handleDrop: (e) =>
    e.stopPropagation()
    e.preventDefault()

    $target = $(e.currentTarget)
    $target.removeClass("over")
    true

  handleDropOnBody: (e) =>
    e.preventDefault()
    e.stopPropagation()

  handleDragEnd: (e) =>
    $target = $(e.currentTarget)
    $target.removeClass("over")
    true

  handleOnMouseOverDragHandle: (e) =>
    $target = $(e.currentTarget)
    $wrapper = $target.closest(@draggableWrapper)
    $wrapper.addClass("draggable")
    $wrapper.attr("draggable", true)

  handleOnMouseOutDragHandle: (e) =>
    $target = $(e.currentTarget)
    $wrapper = $target.closest(@draggableWrapper)
    $wrapper.removeClass("draggable")
    $wrapper.attr("draggable", false)

window.draggable = new DraggableController()
