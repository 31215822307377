import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["saleStatusSelect", "saleStatusMassSelect", "askingPriceTextField", "askingPriceMassTextField", "commentTextField", "commentMassTextField"]

  updateAllSaleStatusSelects() {
    var value = this.saleStatusMassSelectTarget.value;
    for(let selectTag of this.saleStatusSelectTargets) {
      selectTag.value = value;
    }
    this.closeDropdown();
  }

  updateAllAskingPriceTextFields() {
    var value = this.askingPriceMassTextFieldTarget.value;
    for(let textField of this.askingPriceTextFieldTargets) {
      textField.value = value;
    }
    this.closeDropdown();
  }

  updateAllCommentTextFields() {
    var value = this.commentMassTextFieldTarget.value;
    for(let textField of this.commentTextFieldTargets) {
      textField.value = value;
    }
    this.closeDropdown();
  }

  closeDropdown(){
    $(".big-dropdown-toggle").trigger("big-dropdown:close");
  }
}
